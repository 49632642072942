import IconLargeGearBoots from "@/components/Icon/IconLargeGearBoots.svg";
import IconLargeGearClearance from "@/components/Icon/IconLargeGearClearance.svg";
import IconLargeGearGloves from "@/components/Icon/IconLargeGearGloves.svg";
import IconLargeGearHelmet from "@/components/Icon/IconLargeGearHelmet.svg";
import IconLargeGearIntercoms from "@/components/Icon/IconLargeGearIntercoms.svg";
import IconLargeGearJacket from "@/components/Icon/IconLargeGearJacket.svg";
import IconLargeGearLeatherSuit from "@/components/Icon/IconLargeGearLeatherSuit.svg";
import IconLargeGearPants from "@/components/Icon/IconLargeGearPants.svg";
import IconLargeGearProtection from "@/components/Icon/IconLargeGearProtection.svg";
import IconLargeGearThermal from "@/components/Icon/IconLargeGearThermal.svg";
import IconLargeGearVisor from "@/components/Icon/IconLargeGearVisor.svg";
import IconLargeGearWetWeather from "@/components/Icon/IconLargeGearWetWeather.svg";
import IconLargeGearCasualWear from "@/components/Icon/IconLargeGearCasualWear.svg";

import IconLargeAdventureBoots from "@/components/Icon/IconLargeAdventureBoots.svg";
import IconLargeAdventureGloves from "@/components/Icon/IconLargeAdventureGloves.svg";
import IconLargeAdventureHelmet from "@/components/Icon/IconLargeAdventureHelmet.svg";
import IconLargeAdventureJacket from "@/components/Icon/IconLargeAdventureJacket.svg";
import IconLargeAdventurePants from "@/components/Icon/IconLargeAdventurePants.svg";
import IconLargeAdventureVisors from "@/components/Icon/IconLargeAdventureVisors.svg";

import IconBearings from "@/components/Icon/IconBearings.svg";
import IconBody from "@/components/Icon/IconBody.svg";
import IconBrakes from "@/components/Icon/IconBrakes.svg";
import IconChain from "@/components/Icon/IconChain.svg";
import IconClutch from "@/components/Icon/IconClutch.svg";
import IconElectrical from "@/components/Icon/IconElectrical.svg";
import IconEngine from "@/components/Icon/IconEngine.svg";
import IconExhaust from "@/components/Icon/IconExhaust.svg";
import IconFluids from "@/components/Icon/IconFluids.svg";
import IconFootControls from "@/components/Icon/IconFootControls.svg";
import IconHandControls from "@/components/Icon/IconHandControls.svg";
import IconLighting from "@/components/Icon/IconLighting.svg";
import IconLuggage from "@/components/Icon/IconLuggage.svg";
import IconMirrors from "@/components/Icon/IconMirrors.svg";
import IconPartsProtection from "@/components/Icon/IconPartsProtection.svg";
import IconPlastics from "@/components/Icon/IconPlastics.svg";
import IconSecurity from "@/components/Icon/IconSecurity.svg";
import IconService from "@/components/Icon/IconService.svg";
import IconStorage from "@/components/Icon/IconStorage.svg";
import IconTyres from "@/components/Icon/IconTyres.svg";

import IconLargeMXBoots from "@/components/Icon/IconLargeMXBoots.svg";
import IconLargeMXGloves from "@/components/Icon/IconLargeMXGloves.svg";
import IconLargeMXGoggles from "@/components/Icon/IconLargeMXGoggles.svg";
import IconLargeMXHelmet from "@/components/Icon/IconLargeMXHelmet.svg";
import IconLargeMXJersey from "@/components/Icon/IconLargeMXJersey.svg";
import IconLargeMXPants from "@/components/Icon/IconLargeMXPants.svg";
import IconLargeMxProtections from "@/components/Icon/IconLargeMxProtections.svg";

export const collectionIcons = [
  {
    department: "Road Gear",
    name: "Road Boots",
    slug: "road-boots",
    icon: IconLargeGearBoots.src,
  },
  {
    department: "Road Gear",
    name: "Road Clearance",
    slug: "road-clearance",
    icon: IconLargeGearClearance.src,
  },
  {
    department: "Road Gear",
    name: "Road Helmets",
    slug: "road-helmets",
    icon: IconLargeGearHelmet.src,
  },
  {
    department: "Road Gear",
    name: "Road Jackets",
    slug: "road-jackets",
    icon: IconLargeGearJacket.src,
  },
  {
    department: "Road Gear",
    name: "Road Pants",
    slug: "road-pants",
    icon: IconLargeGearPants.src,
  },
  {
    department: "Road Gear",
    name: "Road Thermal",
    slug: "road-thermal",
    icon: IconLargeGearThermal.src,
  },
  {
    department: "Road Gear",
    name: "Road Wet Weather",
    slug: "road-wet-weather",
    icon: IconLargeGearWetWeather.src,
  },
  {
    department: "Road Gear",
    name: "Road Casual Wear",
    slug: "road-casual-wear",
    icon: IconLargeGearCasualWear.src,
  },
  {
    department: "Road Gear",
    name: "Road Gloves",
    slug: "road-gloves",
    icon: IconLargeGearGloves.src,
  },
  {
    department: "Road Gear",
    name: "Road Intercoms",
    slug: "road-intercoms-and-bluetooth-kits",
    icon: IconLargeGearIntercoms.src,
  },
  {
    department: "Road Gear",
    name: "Road Leather Suits",
    slug: "road-leather-suits",
    icon: IconLargeGearLeatherSuit.src,
  },
  {
    department: "Road Gear",
    name: "Road Protection",
    slug: "road-protection",
    icon: IconLargeGearProtection.src,
  },
  {
    department: "Road Gear",
    name: "Road Visors and Goggles",
    slug: "road-visors-and-goggles",
    icon: IconLargeGearVisor.src,
  },

  {
    department: "MX Gear",
    name: "MX Boots",

    slug: "mx-boots",
    icon: IconLargeMXBoots.src,
  },
  {
    department: "MX Gear",
    name: "MX Clearance",
    slug: "mx-clearance",
    icon: IconLargeGearClearance.src,
  },
  {
    department: "MX Gear",
    name: "MX Gloves",
    slug: "mx-gloves",
    icon: IconLargeMXGloves.src,
  },
  {
    department: "MX Gear",
    name: "MX Goggles",
    slug: "mx-goggles",
    icon: IconLargeMXGoggles.src,
  },
  {
    department: "MX Gear",
    name: "MX Helmets",
    slug: "mx-helmets",
    icon: IconLargeMXHelmet.src,
  },
  {
    department: "MX Gear",
    name: "MX Jerseys",
    slug: "mx-jersey",
    icon: IconLargeMXJersey.src,
  },
  {
    department: "MX Gear",
    name: "MX Pants",
    slug: "mx-pants",
    icon: IconLargeMXPants.src,
  },
  {
    department: "MX Gear",
    name: "MX Protection",
    slug: "mx-protection",
    icon: IconLargeMxProtections.src,
  },

  {
    department: "Adventure Gear",
    name: "Adventure Boots",
    slug: "adventure-boots",
    icon: IconLargeAdventureBoots.src,
  },
  {
    department: "Adventure Gear",
    name: "Adventure Clearance",
    slug: "adventure-clearance",
    icon: IconLargeGearClearance.src,
  },
  {
    department: "Adventure Gear",
    name: "Adventure Gloves",
    slug: "adventure-gloves",
    icon: IconLargeAdventureGloves.src,
  },
  {
    department: "Adventure Gear",
    name: "Adventure Helmets",
    slug: "adventure-helmets",
    icon: IconLargeAdventureHelmet.src,
  },
  {
    department: "Adventure Gear",
    name: "Adventure Jackets",
    slug: "adventure-jackets",
    icon: IconLargeAdventureJacket.src,
  },
  {
    department: "Adventure Gear",
    name: "Adventure Pants",
    slug: "adventure-pants",
    icon: IconLargeAdventurePants.src,
  },
  {
    department: "Adventure Gear",
    name: "Adventure Visors",
    slug: "adventure-visors",
    icon: IconLargeAdventureVisors.src,
  },

  {
    department: "Parts & Accessories",
    name: "Bearings & Seals",
    slug: "bearings-and-seals",
    icon: IconBearings.src,
  },
  {
    department: "Parts & Accessories",
    name: "Body",
    slug: "body",
    icon: IconBody.src,
  },
  {
    department: "Parts & Accessories",
    name: "Brakes",
    slug: "brakes",
    icon: IconBrakes.src,
  },
  {
    department: "Parts & Accessories",
    name: "Clutch",
    slug: "clutch",
    icon: IconClutch.src,
  },
  {
    department: "Parts & Accessories",
    name: "Drive",
    slug: "drive",
    icon: IconChain.src,
  },
  {
    department: "Parts & Accessories",
    name: "Electrical",
    slug: "electrical",
    icon: IconElectrical.src,
  },
  {
    department: "Parts & Accessories",
    name: "Engine",
    slug: "engine",
    icon: IconEngine.src,
  },
  {
    department: "Parts & Accessories",
    name: "Exhausts & Performance",
    slug: "exhausts-and-performance",
    icon: IconExhaust.src,
  },
  {
    department: "Parts & Accessories",
    name: "Fluids & Consumables",
    slug: "fluids-and-consumables",
    icon: IconFluids.src,
  },
  {
    department: "Parts & Accessories",
    name: "Foot Controls",
    slug: "foot-controls",
    icon: IconFootControls.src,
  },
  {
    department: "Parts & Accessories",
    name: "Hand Controls",
    slug: "hand-controls",
    icon: IconHandControls.src,
  },
  {
    department: "Parts & Accessories",
    name: "Lighting & Electrical",
    slug: "lighting-and-electrical",
    icon: IconLighting.src,
  },
  {
    department: "Parts & Accessories",
    name: "Luggage",
    slug: "luggage",
    icon: IconLuggage.src,
  },
  {
    department: "Parts & Accessories",
    name: "Mirrors",
    slug: "mirrors",
    icon: IconMirrors.src,
  },
  {
    department: "Parts & Accessories",
    name: "Plastics",
    slug: "plastics",
    icon: IconPlastics.src,
  },
  {
    department: "Parts & Accessories",
    name: "Protection",
    slug: "protection",
    icon: IconPartsProtection.src,
  },
  {
    department: "Parts & Accessories",
    name: "Security",
    slug: "security",
    icon: IconSecurity.src,
  },
  {
    department: "Parts & Accessories",
    name: "Service & Maintenance",
    slug: "service-and-maintenance",
    icon: IconService.src,
  },
  {
    department: "Parts & Accessories",
    name: "Storage & Transport",
    slug: "storage-and-transport",
    icon: IconStorage.src,
  },
  {
    department: "Parts & Accessories",
    name: "Tyres & Wheels",
    slug: "tyres-and-wheels",
    icon: IconTyres.src,
  },
  {
    department: "Parts & Accessories",
    name: "Adventure Goggles",
    slug: "adventure-goggles",
    icon: IconLargeMXGoggles.src,
  },
  {
    department: "Parts & Accessories",
    name: "Adventure Thermal",
    slug: "adventure-goggles",
    icon: IconLargeGearThermal.src,
  },
];

export const departments = [
  {
    name: "Road Gear",
    slug: "road-gear",
    collections: [
      {
        name: "Road Boots",
        slug: "road-boots",
        icon: IconLargeGearBoots.src,
        categories: [
          {
            name: "Road Casual riding shoes",
            slug: "road-casual-riding-shoes",
          },
          {
            name: "Road Full length boots",
            slug: "road-full-length-boots",
          },
          {
            name: "Road Short boots",
            slug: "road-short-boots",
          },
          {
            name: "Road Women’s boots",
            slug: "road-womens-boots",
          },
        ],
      },
      {
        name: "Road Clearance",
        slug: "road-clearance",
        icon: IconLargeGearClearance.src,
        categories: [
          {
            name: "Road Under $100",
            slug: "road-gear-under-100",
          },
          {
            name: "Road Under $200",
            slug: "road-gear-under-200",
          },
          {
            name: "Road Under $500",
            slug: "road-gear-under-500",
          },
        ],
      },
      {
        name: "Road Helmets",
        slug: "road-helmets",
        icon: IconLargeGearHelmet.src,
        categories: [
          {
            name: "Road Open Face Helmets",
            slug: "road-open-face-helmets",
          },
          {
            name: "Road Full Face Helmets",
            slug: "road-full-face-helmets",
          },
          {
            name: "Road Modular Helmets",
            slug: "road-modular-helmets",
          },
          {
            name: "Road Helmet Accessories",
            slug: "road-helmet-accessories",
          },
        ],
      },
      {
        name: "Road Jackets",
        slug: "road-jackets",
        icon: IconLargeGearJacket.src,
        categories: [
          {
            name: "Road Short Jackets",
            slug: "road-short-jackets",
          },
          {
            name: "Road 3/4 Length Jackets",
            slug: "road-34-length-jackets",
          },
          {
            name: "Road Women's Jackets",
            slug: "road-womens-jackets",
          },
        ],
      },
      {
        name: "Road Pants",
        slug: "road-pants",
        icon: IconLargeGearPants.src,
        categories: [
          {
            name: "Road Riding Jeans",
            slug: "road-riding-jeans",
          },
          {
            name: "Road Leather Pants",
            slug: "road-leather-pants",
          },
          {
            name: "Road Textile Pants",
            slug: "road-textile-pants",
          },
          {
            name: "Road Women's Pants",
            slug: "road-womens-pants",
          },
        ],
      },
      {
        name: "Road Thermal",
        slug: "road-thermal",
        icon: IconLargeGearThermal.src,
        categories: [
          {
            name: "Road Gloves",
            slug: "road-thermal-gloves",
          },
          {
            name: "Road Neck & Head",
            slug: "road-thermal-neck-head",
          },
          {
            name: "Road Pants",
            slug: "road-thermal-pants",
          },
          {
            name: "Road Shirts",
            slug: "road-thermal-shirts",
          },
          {
            name: "Road Socks",
            slug: "road-thermal-socks",
          },
        ],
      },
      {
        name: "Road Wet Weather",
        slug: "road-wet-weather",
        icon: IconLargeGearWetWeather.src,
        categories: [
          {
            name: "Road Jacket",
            slug: "road-wet-weather-jacket",
          },
          {
            name: "Road One Piece Suit",
            slug: "road-wet-weather-one-piece-suit",
          },
          {
            name: "Road Pants",
            slug: "road-wet-weather-pants",
          },
        ],
      },
      {
        name: "Road Casual Wear",
        slug: "road-casual-wear",
        icon: IconLargeGearCasualWear.src,
        categories: [
          {
            name: "Road Hats",
            slug: "road-casual-hats",
          },
          {
            name: "Road Shirts",
            slug: "road-casual-shirts",
          },
          {
            name: "Road Jumpers",
            slug: "road-casual-jumpers",
          },
          {
            name: "Road Socks",
            slug: "road-casual-socks",
          },
          {
            name: "Road Belts",
            slug: "road-casual-belts",
          },
        ],
      },
      {
        name: "Road Gloves",
        slug: "road-gloves",
        icon: IconLargeGearGloves.src,
        categories: [
          {
            name: "Road Short Cuff Gloves",
            slug: "road-short-cuff-gloves",
          },
          {
            name: "Road Long Cuff Gloves",
            slug: "road-long-cuff-gloves",
          },
          {
            name: "Road Women's Gloves",
            slug: "road-womens-gloves",
          },
        ],
      },
      {
        name: "Road Intercoms",
        slug: "road-intercoms-and-bluetooth-kits",
        icon: IconLargeGearIntercoms.src,
        categories: [
          {
            name: "Road Single Units",
            slug: "single-units",
          },
          {
            name: "Road Twin Units",
            slug: "twin-units",
          },
        ],
      },
      {
        name: "Road Leather Suits",
        slug: "road-leather-suits",
        icon: IconLargeGearLeatherSuit.src,
        categories: [
          {
            name: "Road Base Layers",
            slug: "road-base-layers",
          },
          {
            name: "Road One Piece Leather Suits",
            slug: "road-one-piece-leather-suits",
          },
          {
            name: "Road Two Piece Leather Suits",
            slug: "road-two-piece-leather-suits",
          },
          {
            name: "Road Women's Leather Suits",
            slug: "road-womens-leather-suits",
          },
        ],
      },
      {
        name: "Road Protection",
        slug: "road-protection",
        icon: IconLargeGearProtection.src,
        categories: [
          {
            name: "Road Back Protection",
            slug: "road-back-protection",
          },
          {
            name: "Road Chest Protection",
            slug: "road-chest-protection",
          },
          {
            name: "Road Ear Protection",
            slug: "road-ear-protection",
          },
          {
            name: "Road Elbow Protection",
            slug: "road-elbow-protection",
          },
          {
            name: "Road Full Body Protection",
            slug: "road-full-body-protection",
          },
          {
            name: "Road Knee Protection",
            slug: "road-knee-protection",
          },
          {
            name: "Road Shoulder Protection",
            slug: "road-shoulder-protection",
          },
        ],
      },
      {
        name: "Road Visors & Goggles",
        slug: "road-visors-and-goggles",
        icon: IconLargeGearVisor.src,
        categories: [
          {
            name: "Road Dual Sport Visors",
            slug: "road-dual-sport-visors",
          },
          {
            name: "Road Full Face Visors",
            slug: "road-full-face-visors",
          },
          {
            name: "Road Open Face Visors",
            slug: "road-open-face-visors",
          },
          {
            name: "Road Pinlocks",
            slug: "road-pinlocks",
          },
          {
            name: "Road Road Goggles",
            slug: "road-road-goggles",
          },
        ],
      },
    ],
  },
  {
    name: "MX Gear",
    slug: "mx-gear",
    collections: [
      {
        name: "MX MBoots",
        slug: "mx-boots",
        icon: IconLargeMXBoots.src,
        categories: [
          {
            name: "MX Adult Boots",
            slug: "adult-mx-boots",
          },
          {
            name: "MX Youth Boots",
            slug: "youth-mx-boots",
          },
          {
            name: "MX Kids Boot",
            slug: "kids-mx-boots",
          },
        ],
      },
      {
        name: "MX Clearance",
        slug: "mx-clearance",
        icon: IconLargeGearClearance.src,
        categories: [
          {
            name: "MX Under $50",
            slug: "mx-under-50",
          },
          {
            name: "MX Under $100",
            slug: "mx-under-100",
          },
          {
            name: "MX Under $500",
            slug: "mx-under-500",
          },
        ],
      },
      {
        name: "MX Gloves",
        slug: "mx-gloves",
        icon: IconLargeMXGloves.src,
        categories: [
          {
            name: "MX Adult MX Gloves",
            slug: "adult-mx-gloves",
          },
          {
            name: "MX Women's MX Gloves",
            slug: "womens-mx-gloves",
          },
          {
            name: "MX Youth MX Gloves",
            slug: "youth-mx-gloves",
          },
        ],
      },
      {
        name: "MX Goggles",
        slug: "mx-goggles",
        icon: IconLargeMXGoggles.src,
        categories: [
          {
            name: "MX Goggles",
            slug: "mx-goggles",
          },
          {
            name: "MX Goggles Accessories",
            slug: "mx-goggles-accessories",
          },
          {
            name: "MX Tear Offs",
            slug: "mx-tear-offs",
          },
          {
            name: "MX Roll Off Systems",
            slug: "mx-roll-off-systems",
          },
          {
            name: "MX Replacement Lenses",
            slug: "mx-replacement-lenses",
          },
        ],
      },
      {
        name: "MX Helmets",
        slug: "mx-helmets",
        icon: IconLargeMXHelmet.src,
        categories: [
          {
            name: "MX Adult MX Helmets",
            slug: "adult-mx-helmet",
          },
          {
            name: "MX Youth MX Helmets",
            slug: "youth-mx-helmet",
          },
        ],
      },
      {
        name: "MX Jerseys",
        slug: "mx-jersey",
        icon: IconLargeMXJersey.src,
        categories: [
          {
            name: "MX Adult Jerseys",
            slug: "adult-mx-jersey",
          },
          {
            name: "MX Women's Jerseys",
            slug: "womens-mx-jersey",
          },
          {
            name: "MX Youth Jerseys",
            slug: "youth-mx-jersey",
          },
          {
            name: "MX Kids Jerseys",
            slug: "kids-mx-jersey",
          },
        ],
      },
      {
        name: "MX Pants",
        slug: "mx-pants",
        icon: IconLargeMXPants.src,
        categories: [
          {
            name: "MX Adult MX Pants",
            slug: "adult-mx-pants",
          },
          {
            name: "MX Women's MX Pants",
            slug: "womens-mx-pants",
          },
          {
            name: "MX Youth MX Pants",
            slug: "youth-mx-pants",
          },
          {
            name: "MX Kids MX Pants",
            slug: "kids-mx-pants",
          },
        ],
      },
      {
        name: "MX Protection",
        slug: "mx-protection",
        icon: IconLargeMxProtections.src,
        categories: [
          {
            name: "MX Back Protection",
            slug: "mx-back-protection",
          },
          {
            name: "MX Chest Protection",
            slug: "mx-chest-protection",
          },
          {
            name: "MX Ear Protection",
            slug: "mx-ear-protection",
          },
          {
            name: "MX Elbow Protection",
            slug: "mx-elbow-protection",
          },
          {
            name: "MX Knee Protection",
            slug: "mx-knee-protection",
          },
          {
            name: "MX Neck Protection",
            slug: "mx-neck-protection",
          },
          {
            name: "MX Protective Undergarments",
            slug: "mx-protective-undergarments",
          },
          {
            name: "MX Shoulder Protection",
            slug: "mx-shoulder-protection",
          },
          {
            name: "MX Socks",
            slug: "mx-socks",
          },
          {
            name: "MX Torso Protection",
            slug: "mx-torso-protection",
          },
        ],
      },
    ],
  },
  {
    name: "Adventure Gear",
    slug: "adventure-gear",
    collections: [
      {
        name: "Adventure Boots",
        slug: "adventure-boots",
        icon: IconLargeAdventureBoots.src,
        categories: [
          {
            name: "Adventure Short Boots",
            slug: "adv-short-boots",
          },
          {
            name: "Adventure Full Length Boots ",
            slug: "adv-full-length-boots",
          },
        ],
      },
      {
        name: "Adventure Clearance",
        slug: "adventure-clearance",
        icon: IconLargeGearClearance.src,
        categories: [
          {
            name: "Adventure Under $50",
            slug: "adv-under-50",
          },
          {
            name: "Adventure Under $100",
            slug: "adv-under-100",
          },
          {
            name: "Adventure Under $200",
            slug: "adv-under-200",
          },
          {
            name: "Adventure Under $500",
            slug: "adv-under-500",
          },
        ],
      },
      {
        name: "Adventure Gloves",
        slug: "adventure-gloves",
        icon: IconLargeAdventureGloves.src,
        categories: [
          {
            name: "Adventure Short Cuff Gloves",
            slug: "adv-short-cuff-gloves",
          },
          {
            name: "Adventure Long Cuff Gloves",
            slug: "adv-long-cuff-gloves",
            slug: "womens-adventure-gloves",
          },
        ],
      },
      {
        name: "Adventure Helmets",
        slug: "adventure-helmets",
        icon: IconLargeAdventureHelmet.src,
        categories: [
          {
            name: "Adventure Helmets",
            slug: "adv-helmets",
          },
          {
            name: "Adventure Helmet Accessories",
            slug: "adv-helmet-accessories",
          },
        ],
      },
      {
        name: "Adventure Jackets",
        slug: "adventure-jackets",
        icon: IconLargeAdventureJacket.src,
        categories: [
          {
            name: "Adventure Men's Jackets",
            slug: "adv-mens-jackets",
          },
          {
            name: "Adventure Women's Jackets",
            slug: "adv-womens-jackets",
          },
        ],
      },
      {
        name: "Adventure Pants",
        slug: "adventure-pants",
        icon: IconLargeAdventurePants.src,
        categories: [
          {
            name: "Adventure Men's Adventure Pants",
            slug: "adv-mens-pants",
          },
          {
            name: "Adventure Women's Adventure Pants",
            slug: "adv-womens-pants",
          },
        ],
      },
      {
        name: "Adventure Visors",
        slug: "adventure-visors",
        icon: IconLargeAdventureVisors.src,
        categories: [
          {
            name: "Adventure Full Face Visors",
            slug: "adv-full-face-visors",
          },
          {
            name: "Adventure Pinlocks",
            slug: "adv-pinlocks",
          },
          {
            name: "Adventure Dual Sport Visors",
            slug: "adv-dual-sport-visors",
          },
        ],
      },
    ],
  },
  {
    name: "Accessories",
    slug: "accessories",
    collections: [
      {
        name: "Bearings & Seals",
        slug: "bearings-and-seals",
        icon: IconBearings.src,
        categories: [
          {
            name: "Fork Seals",
            slug: "fork-seals",
          },
          {
            name: "Linkage Kits",
            slug: "linkage-kits",
          },
          {
            name: "Shocks",
            slug: "shocks",
          },
          {
            name: "Steering",
            slug: "steering",
          },
          {
            name: "Swingarm",
            slug: "swingarm",
          },
        ],
      },
      {
        name: "Body",
        slug: "body",
        icon: IconBody.src,
        categories: [
          {
            name: "Blanking Plates & Frame Plugs",
            slug: "blanking-plates-frame-plugs",
          },
          {
            name: "Bolt Kits",
            slug: "bolt-kits",
          },
          {
            name: "Breather Vents",
            slug: "breather-vents",
          },
          {
            name: "Fairings",
            slug: "fairings",
          },
          {
            name: "Fender",
            slug: "fender",
          },
          {
            name: "Fluid Reservoirs & Caps",
            slug: "fluid-reservoirs-caps",
          },
          {
            name: "Fuel Caps",
            slug: "fuel-caps",
          },
          {
            name: "Screens & Shields",
            slug: "screens-shields",
          },
          {
            name: "Seats & Covers",
            slug: "seats-and-covers",
          },
          {
            name: "Swingarm Spools",
            slug: "swingarm-spools",
          },
          {
            name: "Tail Tidy",
            slug: "tail-tidy",
          },
        ],
      },
      {
        name: "Brakes",
        slug: "brakes",
        icon: IconBrakes.src,
        categories: [
          {
            name: "Brake Components",
            slug: "brake-components",
          },
          {
            name: "Brake Discs",
            slug: "brake-discs",
          },
          {
            name: "Brake Pads",
            slug: "brake-pads",
          },
        ],
      },
      {
        name: "Clutch",
        slug: "clutch",
        icon: IconClutch.src,
        categories: [
          {
            name: "Clutch Components",
            slug: "clutch-components",
          },
          {
            name: "Clutch Kits",
            slug: "clutch-kits",
          },
        ],
      },

      {
        name: "Drive",
        slug: "drive",
        icon: IconChain.src,
        categories: [
          {
            name: "Chain & Sprocket Kits",
            slug: "chain-sprocket-kits",
          },
          {
            name: "Chain Adjusters",
            slug: "chain-adjusters",
          },
          {
            name: "Chain Guides",
            slug: "chain-guides",
          },
          {
            name: "Chain Rollers",
            slug: "chain-rollers",
          },
          {
            name: "Chains",
            slug: "chains",
          },
          {
            name: "Front Sprockets",
            slug: "front-sprockets",
          },
          {
            name: "Rear Sprockets",
            slug: "rear-sprockets",
          },
        ],
      },
      {
        name: "Electrical",
        slug: "electrical",
        icon: IconElectrical.src,
        categories: [
          {
            name: "Batteries",
            slug: "batteries",
          },
          {
            name: "Battery Chargers",
            slug: "battery-chargers",
          },
          {
            name: "Electrical Accessories",
            slug: "electrical-accessories",
          },
          {
            name: "Fuel & Ignition Tuning",
            slug: "fuel-and-ignition-tuning",
          },
          {
            name: "Hour Meters",
            slug: "hour-meters",
          },
          {
            name: "Starters",
            slug: "starters",
          },
          {
            name: "Switches",
            slug: "switches",
          },
        ],
      },
      {
        name: "Engine",
        slug: "engine",
        icon: IconEngine.src,
        categories: [
          {
            name: "Bearings & Seals",
            slug: "bearings-and-seals",
          },
          {
            name: "Big Bore Kits",
            slug: "big-bore-kits",
          },
          {
            name: "Camshafts",
            slug: "camshafts",
          },
          {
            name: "Carburettor Parts",
            slug: "carburettor-parts",
          },
          {
            name: "Conrods",
            slug: "conrods",
          },
          {
            name: "Covers",
            slug: "covers",
          },
          {
            name: "Crank Kits",
            slug: "crank-kits",
          },
          {
            name: "Cylinders",
            slug: "cylinders",
          },
          {
            name: "Engine Accessories",
            slug: "engine-accessories",
          },
          {
            name: "Gasket Kits",
            slug: "gasket-kits",
          },
          {
            name: "Hoses",
            slug: "hoses",
          },
          {
            name: "Oil Filler Cap",
            slug: "oil-filler-caps",
          },
          {
            name: "Performance Heads",
            slug: "performance-heads",
          },
          {
            name: "Piston Kits",
            slug: "piston-kits",
          },
          {
            name: "Spark Plugs",
            slug: "spark-plugs",
          },
        ],
      },
      {
        name: "Exhausts & Performance",
        slug: "exhausts-and-performance",
        icon: IconExhaust.src,
        categories: [
          {
            name: "2 Stroke Pipes",
            slug: "2-stroke-pipes",
          },
          {
            name: "2 Stroke Silencers",
            slug: "2-stroke-silencers",
          },
          {
            name: "3/4 Exhausts",
            slug: "34-exhausts",
          },
          {
            name: "Baffles & Inserts",
            slug: "baffles-inserts",
          },
          {
            name: "Clamps & Hangers",
            slug: "clamps-hangers",
          },
          {
            name: "Exhaust Accessories",
            slug: "exhaust-accessories",
          },
          {
            name: "Exhaust Plugs",
            slug: "exhaust-plugs",
          },
          {
            name: "Exhaust Springs",
            slug: "exhaust-springs",
          },
          {
            name: "Full Exhaust Systems",
            slug: "full-exhaust-systems",
          },
          {
            name: "Headers",
            slug: "headers",
          },
          {
            name: "Link Mid Pipes",
            slug: "link-mid-pipes",
          },
          {
            name: "Power Commanders",
            slug: "power-commanders",
          },
          {
            name: "Slip On Exhausts",
            slug: "slip-on-exhausts",
          },
        ],
      },
      {
        name: "Fluids & Consumables",
        slug: "fluids-and-consumables",
        icon: IconFluids.src,
        categories: [
          {
            name: "2 Stroke Oil",
            slug: "2-stroke-oil",
          },
          {
            name: "4 Stroke Oil",
            slug: "4-stroke-oil",
          },
          {
            name: "Air Filter Oils",
            slug: "air-filter-oils",
          },
          {
            name: "Brake Fluid",
            slug: "brake-fluid",
          },
          {
            name: "Chain Lube & Clean",
            slug: "chain-lube-clean",
          },
          {
            name: "Coolants",
            slug: "coolants",
          },
          {
            name: "Fork Oil",
            slug: "fork-oil",
          },
          {
            name: "Gear Box Oil",
            slug: "gear-box-oil",
          },
          {
            name: "Maintenance & Service",
            slug: "maintenance-service",
          },
        ],
      },
      {
        name: "Foot Controls",
        slug: "foot-controls",
        icon: IconFootControls.src,
        categories: [
          {
            name: "Brake Pedals",
            slug: "brake-pedals",
          },
          {
            name: "Foot Peg Mounts",
            slug: "foot-peg-mounts",
          },
          {
            name: "Foot Pegs",
            slug: "foot-pegs",
          },
          {
            name: "Gear Levers",
            slug: "gear-levers",
          },
          {
            name: "Kick Start Lever",
            slug: "kick-start-lever",
          },
          {
            name: "Quick Shifters",
            slug: "quick-shifters",
          },
          {
            name: "Rear Sets",
            slug: "rear-sets",
          },
        ],
      },
      {
        name: "Hand Controls",
        slug: "hand-controls",
        icon: IconHandControls.src,
        categories: [
          {
            name: "Bar Clamps",
            slug: "bar-clamps",
          },
          {
            name: "Bar End Adaptors",
            slug: "bar-end-adaptors",
          },
          {
            name: "Bar Ends",
            slug: "bar-ends",
          },
          {
            name: "Bar Pads",
            slug: "bar-pads",
          },
          {
            name: "Clutch Cables",
            slug: "clutch-cables",
          },
          {
            name: "Grip Accessories",
            slug: "grip-accessories",
          },
          {
            name: "Grips",
            slug: "grips",
          },
          {
            name: "Handguards",
            slug: "handguards",
          },
          {
            name: "Handlebars, Mounts & Risers",
            slug: "handlebars-mounts-risers",
          },
          {
            name: "Heated Grips",
            slug: "heated-grips",
          },
          {
            name: "Levers",
            slug: "levers",
          },
          {
            name: "Throttle Cables",
            slug: "throttle-cables",
          },
          {
            name: "Throttle Kits",
            slug: "throttle-kits",
          },
          {
            name: "Triple Clamps",
            slug: "triple-clamps",
          },
        ],
      },
      {
        name: "Lighting & Electrical",
        slug: "lighting-and-electrical",
        icon: IconLighting.src,
        categories: [
          {
            name: "Bulbs",
            slug: "bulbs",
          },
          {
            name: "Headlights & Guards",
            slug: "headlights-guards",
          },
          {
            name: "Indicator Adaptors",
            slug: "indicator-adaptors",
          },
          {
            name: "Indicator Wiring Kits",
            slug: "wiring-kits",
          },
          {
            name: "Indicators",
            slug: "indicators",
          },
          {
            name: "Light Mounts",
            slug: "light-mounts",
          },
          {
            name: "Lighting Accessories",
            slug: "lighting-accessories",
          },
          {
            name: "Running & Drive Lights",
            slug: "running-drive-lights",
          },
          {
            name: "Tail & Brake Lights",
            slug: "tail-brake-lights",
          },
        ],
      },
      {
        name: "Luggage",
        slug: "luggage",
        icon: IconLuggage.src,
        categories: [
          {
            name: "Fitting Kits",
            slug: "fitting-kits",
          },
          {
            name: "Panniers",
            slug: "panniers",
          },
          {
            name: "Phone & GPS Mounts",
            slug: "phone-gps-mounts",
          },
          {
            name: "Racks",
            slug: "racks",
          },
          {
            name: "Saddlebags",
            slug: "saddlebags",
          },
          {
            name: "Tail Bags",
            slug: "tail-bags",
          },
          {
            name: "Tank Bags",
            slug: "tank-bags",
          },
          {
            name: "Top Boxes",
            slug: "top-boxes",
          },
          {
            name: "Backpacks",
            slug: "backpacks",
          },
          {
            name: "Gear Haulers",
            slug: "gear-haulers",
          },
        ],
      },
      {
        name: "Mirrors",
        slug: "mirrors",
        icon: IconMirrors.src,
        categories: [
          {
            name: "Mirror Adaptors",
            slug: "mirror-adaptors",
          },
          {
            name: "Mirror Sets",
            slug: "mirror-sets",
          },
          {
            name: "Single Mirrors",
            slug: "single-mirrors",
          },
        ],
      },
      {
        name: "Plastics",
        slug: "plastics",
        icon: IconPlastics.src,
        categories: [
          {
            name: "Disc Guards",
            slug: "disc-guards",
          },
          {
            name: "Fender",
            slug: "fender",
          },
          {
            name: "Fork Guards",
            slug: "fork-guards",
          },
          {
            name: "Front Plates",
            slug: "front-plates",
          },
          {
            name: "Fuel Tanks",
            slug: "fuel-tanks",
          },
          {
            name: "Ignition Cover",
            slug: "ignition-cover",
          },
          {
            name: "Mud Flaps",
            slug: "mud-flaps",
          },
          {
            name: "Plastic Kits",
            slug: "plastics-kits",
          },
          {
            name: "Radiator Shrouds",
            slug: "radiator-shrouds",
          },
          {
            name: "Side Covers",
            slug: "side-covers",
          },
          {
            name: "Swingarm Sliders",
            slug: "swingarm-sliders",
          },
        ],
      },
      {
        name: "Protection",
        slug: "protection",
        icon: IconPartsProtection.src,
        categories: [
          {
            name: "Axle Sliders",
            slug: "axle-sliders",
          },
          {
            name: "Bash Plates",
            slug: "bash-plates",
          },
          {
            name: "Chain Guards",
            slug: "chain-guards",
          },
          {
            name: "Crash Bars",
            slug: "crash-bars",
          },
          {
            name: "Engine Covers",
            slug: "engine-covers",
          },
          {
            name: "Exhaust Guards",
            slug: "exhaust-guards",
          },
          {
            name: "Fork Protectors",
            slug: "fork-protectors",
          },
          {
            name: "Frame Sliders",
            slug: "frame-sliders",
          },
          {
            name: "Radiator Guards",
            slug: "radiator-guards",
          },
          {
            name: "Sprocket Covers",
            slug: "sprocket-covers",
          },
          {
            name: "Tail Sliders",
            slug: "tail-sliders",
          },
          {
            name: "Tank Protectors and Pads",
            slug: "tank-protectors-pads",
          },
          {
            name: "Dash Protectors",
            slug: "dash-protectors",
          },
        ],
      },
      {
        name: "Security",
        slug: "security",
        icon: IconSecurity.src,
        categories: [
          {
            name: "Alarms",
            slug: "alarms",
          },
          {
            name: "Chains and Cables",
            slug: "chains-cables",
          },
          {
            name: "Disc Locks",
            slug: "disc-locks",
          },
          {
            name: "Helmet Locks",
            slug: "helmet-locks",
          },
          {
            name: "Locks",
            slug: "locks",
          },
        ],
      },
      {
        name: "Service & Maintenance",
        slug: "service-and-maintenance",
        icon: IconService.src,
        categories: [
          {
            name: "Air Box Covers",
            slug: "air-box-covers",
          },
          {
            name: "Air Filters and Kits",
            slug: "air-filters-kits",
          },
          {
            name: "Oil Filters and Kits",
            slug: "oil-filter-kits",
          },
          {
            name: "Pick Ups",
            slug: "pick-ups",
          },
          {
            name: "Stands",
            slug: "stands",
          },
          {
            name: "Tools",
            slug: "tools",
          },
        ],
      },
      {
        name: "Storage & Transport",
        slug: "storage-and-transport",
        icon: IconStorage.src,
        categories: [
          {
            name: "Bike Covers",
            slug: "bike-covers",
          },
          {
            name: "Fork Stands",
            slug: "fork-stands",
          },
          {
            name: "Ramps",
            slug: "ramps",
          },
          {
            name: "Stands",
            slug: "stands",
          },
          {
            name: "Storage",
            slug: "storage",
          },
          {
            name: "Tie Downs",
            slug: "tie-downs",
          },
          {
            name: "Wheel Chocks",
            slug: "wheel-chocks",
          },
          {
            name: "Workshop Mats",
            slug: "workshop-mats",
          },
        ],
      },
      {
        name: "Tyres & Wheels",
        slug: "tyres-and-wheels",
        icon: IconTyres.src,
        categories: [
          {
            name: "Tyre Pair Deals",
            slug: "tyre-pair-deals",
          },
          {
            name: "Adventure Tyres",
            slug: "adventure-tyres",
          },
          {
            name: "Axles and Spacers",
            slug: "axles-and-spacers",
          },
          {
            name: "Front Rims",
            slug: "front-rims",
          },
          {
            name: "Off-Road Tyres",
            slug: "offroad-tyres",
          },
          {
            name: "Quad Bike Tyres",
            slug: "quad-bike-tyres",
          },
          {
            name: "Rear Rims",
            slug: "rear-rims",
          },
          {
            name: "Rim Locks",
            slug: "rim-locks",
          },
          {
            name: "Road Tyres",
            slug: "road-tyres",
          },
          {
            name: "Scooter Tyres",
            slug: "scooter-tyres",
          },
          {
            name: "Training Wheels",
            slug: "training-wheels",
          },
          {
            name: "Tubes",
            slug: "tubes",
          },
          {
            name: "Tyre Warmers",
            slug: "tyre-warmers",
          },
          {
            name: "Wheel Bearing Kits",
            slug: "wheel-bearing-kits",
          },
          {
            name: "Wheel Hardware",
            slug: "wheel-hardware",
          },
          {
            name: "Wheel Kits",
            slug: "wheel-kits",
          },
          {
            name: "Wheel Tape",
            slug: "wheel-tape",
          },
          {
            name: "Race Tyres",
            slug: "race-tyres",
          },
          {
            name: "Gauges and Pumps",
            slug: "gauges-and-pumps",
          },
          {
            name: "Valve Stems",
            slug: "valve-stems",
          },
        ],
      },
    ],
  },
];

export const brands = [
  {
    title: "Alpinestars",
    url: "/brands/alpinestars",
    collections: [
      {
        name: "Road Boots",
        url: "/brands/alpinestars/alpinestars-road-boots",
        icon: IconLargeGearBoots.src,
        categories: [
          {
            name: "Road Casual riding shoes",
            url: "/brands/alpinestars/alpinestars-road-boots/c/alpinestars-road-casual-riding-shoes",
          },
          {
            name: "Road Full length boots",
            url: "/brands/alpinestars/alpinestars-road-boots/c/alpinestars-road-full-length-boots",
          },
          {
            name: "Road Short boots",
            url: "/brands/alpinestars/alpinestars-road-boots/c/alpinestars-road-short-boots",
          },
          {
            name: "Road Women’s boots",
            url: "/brands/alpinestars/alpinestars-road-boots/c/alpinestars-road-womens-boots",
          },
        ],
      },
      {
        name: "Road Clearance",
        url: "/brands/alpinestars/alpinestars-road-clearance",
        icon: IconLargeGearClearance.src,
        categories: [
          {
            name: "Road Under $100",
            url: "/brands/alpinestars/alpinestars-road-clearance/c/alpinestars-under-100",
          },
          {
            name: "Road Under $200",
            url: "/brands/alpinestars/alpinestars-road-clearance/c/alpinestars-under-200",
          },
          {
            name: "Road Under $500",
            url: "/brands/alpinestars/alpinestars-road-clearance/c/alpinestars-under-500",
          },
        ],
      },
      {
        name: "Road Helmets",
        url: "/brands/alpinestars/alpinestars-road-helmets",
        icon: IconLargeGearHelmet.src,
        categories: [
          {
            name: "Road Open Face Helmets",
            url: "/brands/alpinestars/alpinestars-road-helmets/c/alpinestars-road-open-face-helmets",
          },
          {
            name: "Road Full Face Helmets",
            url: "/brands/alpinestars/alpinestars-road-helmets/c/alpinestars-road-full-face-helmets",
          },
          {
            name: "Road Modular Helmets",
            url: "/brands/alpinestars/alpinestars-road-helmets/c/alpinestars-road-modular-helmets",
          },
          {
            name: "Road Helmet Accessories",
            url: "/brands/alpinestars/alpinestars-road-helmets/c/alpinestars-road-helmet-accessories",
          },
        ],
      },
      {
        name: "Road Jackets",
        url: "/brands/alpinestars/alpinestars-road-jackets",
        icon: IconLargeGearJacket.src,
        categories: [
          {
            name: "Road Short Jackets",
            url: "/brands/alpinestars/alpinestars-road-jackets/c/alpinestars-road-short-jackets",
          },
          {
            name: "Road 3/4 Length Jackets",
            url: "/brands/alpinestars/alpinestars-road-jackets/c/alpinestars-road-34-length-jackets",
          },
          {
            name: "Road Women's Jackets",
            url: "/brands/alpinestars/alpinestars-road-jackets/c/alpinestars-road-womens-jackets",
          },
        ],
      },
      {
        name: "Road Pants",
        url: "/brands/alpinestars/alpinestars-road-pants",
        icon: IconLargeGearPants.src,
        categories: [
          {
            name: "Road Riding Jeans",
            url: "/brands/alpinestars/alpinestars-road-pants/c/alpinestars-road-riding-jeans",
          },
          {
            name: "Road Leather Pants",
            url: "/brands/alpinestars/alpinestars-road-pants/c/alpinestars-road-leather-pants",
          },
          {
            name: "Road Textile Pants",
            url: "/brands/alpinestars/alpinestars-road-pants/c/alpinestars-road-textile-pants",
          },
          {
            name: "Road Women's Pants",
            url: "/brands/alpinestars/alpinestars-road-pants/c/alpinestars-road-womens-pants",
          },
        ],
      },
      {
        name: "Road Thermal",
        url: "/brands/alpinestars/alpinestars-road-thermal",
        icon: IconLargeGearThermal.src,
        categories: [
          {
            name: "Road Gloves",
            url: "/brands/alpinestars/alpinestars-road-thermal/c/alpinestars-road-thermal-gloves",
          },
          {
            name: "Road Neck & Head",
            url: "/brands/alpinestars/alpinestars-road-thermal/c/alpinestars-road-thermal-neck-head",
          },
          {
            name: "Road Pants",
            url: "/brands/alpinestars/alpinestars-road-thermal/c/alpinestars-road-thermal-pants",
          },
          {
            name: "Road Shirts",
            url: "/brands/alpinestars/alpinestars-road-thermal/c/alpinestars-road-thermal-shirts",
          },
          {
            name: "Road Socks",
            url: "/brands/alpinestars/alpinestars-road-thermal/c/alpinestars-road-thermal-socks",
          },
        ],
      },
      {
        name: "Road Wet Weather",
        url: "/brands/alpinestars/alpinestars-road-wet-weather",
        icon: IconLargeGearWetWeather.src,
        categories: [
          {
            name: "Road Jacket",
            url: "/brands/alpinestars/alpinestars-road-wet-weather/c/alpinestars-road-wet-weather-jacket",
          },
          {
            name: "Road One Piece Suit",
            url: "/brands/alpinestars/alpinestars-road-wet-weather/c/alpinestars-road-wet-weather-one-piece-suit",
          },
          {
            name: "Road Pants",
            url: "/brands/alpinestars/alpinestars-road-wet-weather/c/alpinestars-road-wet-weather-pants",
          },
        ],
      },
      {
        name: "Road Casual Wear",
        url: "/brands/alpinestars/alpinestars-road-casual-wear",
        icon: IconLargeGearCasualWear.src,
        categories: [
          {
            name: "Road Hats",
            url: "/brands/alpinestars/alpinestars-road-casual-wear/c/alpinestars-road-casual-hats",
          },
          {
            name: "Road Shirts",
            url: "/brands/alpinestars/alpinestars-road-casual-wear/c/alpinestars-road-casual-shirts",
          },
          {
            name: "Road Jumpers",
            url: "/brands/alpinestars/alpinestars-road-casual-wear/c/alpinestars-road-casual-jumpers",
          },
          {
            name: "Road Socks",
            url: "/brands/alpinestars/alpinestars-road-casual-wear/c/alpinestars-road-casual-socks",
          },
          {
            name: "Road Belts",
            url: "/brands/alpinestars/alpinestars-road-casual-wear/c/alpinestars-road-casual-belts",
          },
        ],
      },
      {
        name: "Road Gloves",
        url: "/brands/alpinestars/alpinestars-road-gloves",
        icon: IconLargeGearGloves.src,
        categories: [
          {
            name: "Road Short Cuff Gloves",
            url: "/brands/alpinestars/alpinestars-road-gloves/c/alpinestars-road-short-cuff-gloves",
          },
          {
            name: "Road Long Cuff Gloves",
            url: "/brands/alpinestars/alpinestars-road-gloves/c/alpinestars-road-long-cuff-gloves",
          },
          {
            name: "Road Women's Gloves",
            url: "/brands/alpinestars/alpinestars-road-gloves/c/alpinestars-road-womens-gloves",
          },
        ],
      },
      {
        name: "Road Intercoms",
        url: "/brands/alpinestars/alpinestars-road-intercoms-and-bluetooth-kits",
        icon: IconLargeGearIntercoms.src,
        categories: [
          {
            name: "Road Single Units",
            url: "/brands/alpinestars/alpinestars-road-intercoms-and-bluetooth-kits/c/alpinestars-single-units",
          },
          {
            name: "Road Twin Units",
            url: "/brands/alpinestars/alpinestars-road-intercoms-and-bluetooth-kits/c/alpinestars-twin-units",
          },
        ],
      },
      {
        name: "Road Leather Suits",
        url: "/brands/alpinestars/alpinestars-road-leather-suits",
        icon: IconLargeGearLeatherSuit.src,
        categories: [
          {
            name: "Road Base Layers",
            url: "/brands/alpinestars/alpinestars-road-leather-suits/c/alpinestars-road-base-layers",
          },
          {
            name: "Road One Piece Leather Suits",
            url: "/brands/alpinestars/alpinestars-road-leather-suits/c/alpinestars-road-one-piece-leather-suits",
          },
          {
            name: "Road Two Piece Leather Suits",
            url: "/brands/alpinestars/alpinestars-road-leather-suits/c/alpinestars-road-two-piece-leather-suits",
          },
          {
            name: "Road Women's Leather Suits",
            url: "/brands/alpinestars/alpinestars-road-leather-suits/c/alpinestars-road-womens-leather-suits",
          },
        ],
      },
      {
        name: "Road Protection",
        url: "/brands/alpinestars/alpinestars-road-protection",
        icon: IconLargeGearProtection.src,
        categories: [
          {
            name: "Road Back Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-back-protection",
          },
          {
            name: "Road Chest Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-chest-protection",
          },
          {
            name: "Road Ear Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-ear-protection",
          },
          {
            name: "Road Elbow Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-elbow-protection",
          },
          {
            name: "Road Full Body Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-full-body-protection",
          },
          {
            name: "Road Knee Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-knee-protection",
          },
          {
            name: "Road Shoulder Protection",
            url: "/brands/alpinestars/alpinestars-road-protection/c/alpinestars-road-shoulder-protection",
          },
        ],
      },
      {
        name: "Road Visors & Goggles",
        url: "/brands/alpinestars/alpinestars-road-visors-and-goggles",
        icon: IconLargeGearVisor.src,
        categories: [
          {
            name: "Road Dual Sport Visors",
            url: "/brands/alpinestars/alpinestars-road-visors-and-goggles/c/alpinestars-road-dual-sport-visors",
          },
          {
            name: "Road Full Face Visors",
            url: "/brands/alpinestars/alpinestars-road-visors-and-goggles/c/alpinestars-road-full-face-visors",
          },
          {
            name: "Road Open Face Visors",
            url: "/brands/alpinestars/alpinestars-road-visors-and-goggles/c/alpinestars-road-open-face-visors",
          },
          {
            name: "Road Pinlocks",
            url: "/brands/alpinestars/alpinestars-road-visors-and-goggles/c/alpinestars-road-pinlocks",
          },
          {
            name: "Road Road Goggles",
            url: "/brands/alpinestars/alpinestars-road-visors-and-goggles/c/alpinestars-road-alpinestars-road-goggles",
          },
        ],
      },
    ],
  },
];

export const newBikes = {
  yamaha: {
    logo: {
      src: "/brand-logo-yamaha.svg",
      width: 150,
      height: 154,
    },
    name: "Yamaha",
    categories: [
      {
        name: "supersport",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "naked",
        products: [
          {
            name: "Yamaha MT-10 Range",
            image:
              "https://images.prismic.io/bikebiz/7242c825-17ff-40ed-b9b7-6e4b98f11a05_mt10+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha MT-10 Range",
            image:
              "https://images.prismic.io/bikebiz/7242c825-17ff-40ed-b9b7-6e4b98f11a05_mt10+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha MT-10 Range",
            image:
              "https://images.prismic.io/bikebiz/7242c825-17ff-40ed-b9b7-6e4b98f11a05_mt10+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha MT-10 Range",
            image:
              "https://images.prismic.io/bikebiz/7242c825-17ff-40ed-b9b7-6e4b98f11a05_mt10+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha MT-10 Range",
            image:
              "https://images.prismic.io/bikebiz/7242c825-17ff-40ed-b9b7-6e4b98f11a05_mt10+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/7242c825-17ff-40ed-b9b7-6e4b98f11a05_mt10+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "classic",
        products: [
          {
            name: "Yamaha XSR900 Range",
            image:
              "https://images.prismic.io/bikebiz/34f76641-75a5-444f-a34f-116c91ad167a_xsr900+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha XSR900 Range",
            image:
              "https://images.prismic.io/bikebiz/34f76641-75a5-444f-a34f-116c91ad167a_xsr900+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/34f76641-75a5-444f-a34f-116c91ad167a_xsr900+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "sport touring",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/3d4312906d8e1ded1bed58a604a048df12a73362_2019_fjr1300ae_dnmn_au_stu_003_450x375.jpg?auto=compress,format",
      },
      {
        name: "cruiser",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/1d9c993e-fa29-4479-b168-9f900253bf7c_xv250+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "scooter",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/0fca6cf1-ea7a-4e66-ab9c-36f3e1cb2f66_tmax560+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "adventure",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/10d76b60-bd96-4dc8-993a-f10bd7d57547_tenere+700+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "motocross",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/26d99c26-3c8e-43c6-a3e2-a222024657ac_yz450+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "enduro",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/68a68630-1415-4d86-9913-ce12044f4f41_wr450f+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "fun bike",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/b2be6158-1f57-4d78-9b7b-6d4fef5a144f_ttr50e+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "agricul tural",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/e7351ecc965f92eb2f8905abfdf02e6e22ad00e0_2016_ag200f_sbe_au_stu_003_450x375.jpg?auto=compress,format",
      },
      {
        name: "quad",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/57b01a1a-bd8a-443f-a8cc-d02fff409b2b_yfm90r.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
    ],
    description: [
      "Founded in 1955, Yamaha Motor Co. is a global superpower of motoring technology. In 1968, Yamaha shook the world with its revolutionary dual-sport motorcycle: the DT-1. In the years since, Yamaha has continued to thrill riders with its astounding range of motorcycles, scooters and more. Driven by passion and constant innovation, Yamaha is one of the most exciting names in motorcycling today.",
      "With an award-winning partnership spanning over 15 years, Bikebiz are the go-to experts on Yamaha motorcycles. Our collection of Yamaha bikes includes everything from classic motorcycles, scooters and sport motorbikes to youth motorcycles and off-road motorbikes. Whichever model you choose, you’ll get to enjoy the speed, design, and quality that has made Yamaha a household name.",
      "Shop with Bikebiz for both trusted advice and the peace of mind that comes with buying your Yamaha motorcycle from Australia’s leading Yamaha dealer.",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/029b0cf5-55bc-4f6f-ba74-c4f8035ebb24_YZF-R7+s06.jpg?auto=compress,format&rect=0,240,1280,480&w=1200&h=450",
    ],
  },
  honda: {
    logo: {
      src: "/brand-logo-honda.svg",
      width: 150,
      height: 154,
    },
    name: "Honda",
    categories: [
      {
        name: "supersport",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/e087d35e47d604b68a8174dfa5a4a4dc32b54bbc_honda_cbr1000rr_sp_stock_image_2_bikebiz.jpg?auto=compress,format  ",
      },
      {
        name: "adventure",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/d8ac97e40f9131e1208dfd68575f750df0135bd4_2018_honda_africa_twin_adventure_sports_dct_stock_image_1_bikebiz.jpg?auto=compress,format",
      },
      {
        name: "naked",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/ef6a50aa13f023975ad93036ab7901b361e9bea8_118670_2018_cb1000r.jpg?auto=compress,format",
      },
      {
        name: "touring",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/84d833a4ceedf5d8d09066897c4ddd4572dba56a_2018_honda_goldwing_tour_premium_stock_image_2_bikebiz.jpg?auto=compress,format",
      },
      {
        name: "cruiser",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/82091711793ec0fd92234c9f4f10be4782763a5e_2019_cmx500_grey2.jpg?auto=compress,format",
      },
      {
        name: "scooter",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/307d13c5ee13796ad3b3e577325697457aa17eb4_honda_forza_300_stock_image_1_bikebiz.jpg?auto=compress,format",
      },
      {
        name: "motocross",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/b99c25da1d75c3d539e9afb447d4b29f120f0165_130867_2019_crf450r.jpg?auto=compress,format",
      },
      {
        name: "enduro",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/23d8ebfcedc7cb60f52b8710004afdc94f265dfc_honda_crf450l_stock_image_3_bikebiz.jpg?auto=compress,format",
      },
      {
        name: "func bikes",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/1ec92890a69e54e56094a699b410bb45a948c9db_honda_crf50f_stock_image_1_bikebiz.jpg?auto=compress,format",
      },
      {
        name: "agricul tural",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/12fe39f30930a845424f8da831539d09864fbf6a_honda_ag-xr_stock_image_2_bikebiz.jpg?auto=compress,format",
      },
    ],
    description: [
      "No name in the motorcycling world is more synonymous with dependability than Honda. Since 1946, Honda has become famous for producing motorbikes set apart by their build quality and durability. The Japanese powerhouse has also been at the forefront of motorcycle design and new technologies, with thousands of patents proving their commitment to research and innovation. Offering both performance and a reliable ride, it’s easy to see why Honda has been the world’s leading motorcycle manufacturer for the past 60 years.",
      "Honda’s impressive range includes everything from sports motorcycles and motocross bikes to scooters, cruisers, touring motorcycles and ATVs. Here at Bikebiz, our collection of Honda motorcycles can accommodate a range of tastes and budgets, from stylish cafe racers and off-road motorbikes to roaring, 1000cc supersport bikes. With years of experience riding, inspecting and selling Honda motorcycles, Bikebiz is Australia’s leading expert on Honda motorcycles and products.",
      "Visit Bikebiz to receive trusted advice from our workshop experts, who will ensure your new Honda meets the highest standards of safety before you hit the road. We also offer Australia-wide delivery to cities including Sydney, Melbourne, Brisbane, Adelaide, Perth, Hobart and more, with free shipping available for orders over $90. Check out our full range of Honda motorcycles below, or visit us in store and ride away, hassle free, with Bikebiz!",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/a49d70d095542aa1e57b12c7191356e3cbfb1dcb_honda_mc_2018_cb1000r_blk_product-hero_1140x500.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/248ba2daac4ea97332b5f8b0be3c76c40d6ee20e_honda_cbr1000rr_sp_lifestyle3_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/7a4a8074246a69e59d65ce1a70d86cd7b6e39248_honda_motorcycles_2019_adventure_touring_africa_twin_sports_large_hero_2.jpg?auto=compress,format",
    ],
  },
  kawasaki: {
    logo: {
      src: "/brand-logo-kawasaki.svg",
      width: 150,
      height: 154,
    },
    name: "Kawasaki",
    categories: [
      {
        name: "supersport",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/29b76127-16d3-4523-a36e-86f6d88025f4_Kawasaki_Supersport.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "nakes",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/cff93999-5511-49b3-89a4-a42d264ffd0c_Kawasaki_Naked.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "classic",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/5a6e421b-1a00-4953-99ca-6dc9ddc3ca75_Kawasaki_Classic.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "cruiser",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/bca41ab1-5466-4e14-bef4-8b80f99a00e0_vulvan+900+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "touring",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/320ce211-ffbe-479c-9d0b-f79f5306203c_Kawasaki_Touring.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "agricultural",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/b437c3594ab65c26e90811e2cca61f9a5deae581_stockman-kawasaki-home-page.jpg?auto=compress,format",
      },
      {
        name: "func bikes",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/61d5427e-130a-4ab3-854d-9bcdb42acd27_Kawasaki_Fun_Bikes.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "motocross",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/000623a6-914b-4d76-9836-a5b180968d2c_Kawasaki_Motocross.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "enduro",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/5e20930e-d35e-425b-92f7-a355e4ba6776_Kawasaki_Enduro.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "quad",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/862eaa6a-387a-4b13-bebf-9338e9d30503_kfx50+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
    ],
    description: [
      "Since 1953, Kawasaki has been a pioneer of advanced engineering and new motoring technologies. With the fastest production motorcycle in the world (the Ninja H2R) and several Australian Superbike championship titles to its name, Kawasaki is a clear choice for riders with a need for speed. With a product range that includes everything from enduro motorbikes and youth motorcycles to stylish tourers and rugged ATVs, Kawasaki has something for everyone.",
      "Bikebiz is proud to be an authorised Kawasaki dealer with years of experience in riding, inspecting and selling Kawasaki motorcycles. Offering a collection that includes everything from naked motorcycles and custom cruisers to the wildly popular Ninja series, Bikebiz has the perfect Kawasaki for you. Our passionate and experienced team members know Kawasaki motorcycles inside and out, and can help you to choose the best Kawasaki product for your lifestyle and needs.",
      "Shop with Bikebiz for peace of mind and trusted advice from our workshop experts, who will make sure your Kawasaki motorcycle meets the highest standards before you hit the road. We also offer Australia-wide delivery to cities including Sydney, Melbourne, Brisbane, Adelaide, Perth, Hobart and more, with free shipping on orders over $90. View our full range of Kawasaki motorcycles online, or visit your local Bikebiz store today to browse and buy with confidence!",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/c15b4227cd8a6ba86ddabae0346d228cd2574d60_2019_kawasaki_z1000_detailed6_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/c15b4227cd8a6ba86ddabae0346d228cd2574d60_2019_kawasaki_z1000_detailed6_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/fa94f6a70ac2e2f05e3b5a60042b19c3c422af12_2019_kawasaki_z900_detailed7_bikebiz.jpg?auto=compress,format",
    ],
  },
  bmw: {
    logo: {
      src: "/brand-logo-bmw.svg",
      width: 150,
      height: 154,
    },
    name: "BMW",
    categories: [
      {
        name: "supersport",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/795d8754-4ca6-4c5d-a7ca-262fc65c5338_BMW_Supersport.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "touring",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/c39e430a-0d55-46fc-a7c9-fa2c8a44a416_BMW_Touring.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "roadster",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/87400d64-39ef-4cb9-8d1f-9b661e14f8ba_BMW_Roadster.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "heritage",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/0f52dc08-4e96-4eae-b639-7ea00cc8c0e3_BMW_Heritage.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "adventure",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/c11147dc-71a0-415b-86ee-631c2278808e_BMW_Adventure.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "scooter",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/680b8928-8620-4307-86f0-1568f466a75e_BMW_Scooter.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
    ],
    description: [
      "The motorcycling division of BMW emerged in the 1920s, from its manufacturing of aircraft engines. By turning their industrial expertise towards personal machines, BMW has been able to craft motorcycles which continually challenge industry expectations. BMW introduced the first all-electric maxi scooter with modern drive technology, and was the brand of choice for eight of the 12 longest motorcycle drives in history. With the world’s top-selling travel enduro (the R1200GS) also belonging to BMW, it’s easy to see why so many motorcyclists look to BMW for technology and performance.",
      "BMW offers a range of fantastic motorcycles, from off-road motorbikes and mid-size scooters to 1000cc sports motorcycles. Whether you’re looking for head-turning style or maximum horsepower, Bikebiz has the perfect BMW for your needs. Our knowledgeable team members have an in-depth knowledge of BMW motorcycles and can help choose the perfect motorbike for you.",
      "Shop with Bikebiz for expert advice, helpful tips, and a thorough inspection from our workshop experts, who will ensure that your new BMW meets the highest standards of safety and performance before leaving our store. We can also arrange transport and delivery to cities across Australia, including Melbourne, Sydney, Brisbane, Adelaide, Perth, Hobart and more. Browse our full collection of BMW motorcycles online, or visit us in store and ride away, hassle free, with Bikebiz.",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/1e6bd9405d12cad5b48a5fcfec7a45a323e74afc_bmw_k_1600_b_lifestyle3_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/914a16b2574bbd75e25f8d762ba87ec66433f42e_bwm_f_750_gs_lifestyle1_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/1e6bd9405d12cad5b48a5fcfec7a45a323e74afc_bmw_k_1600_b_lifestyle3_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/c6e416d8e7ea7725fe3af4d7c64af4acaf733bd0_bmw_k_1600_b_lifestyle4_bikebiz.jpg?auto=compress,format",
    ],
  },
  triumph: {
    logo: {
      src: "/brand-logo-triumph.svg",
      width: 150,
      height: 154,
    },
    name: "Triumph",
    categories: [
      {
        name: "roadster",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/08d63cb0-e6dc-4dc6-a404-f7f84a5de482_Triumph_Roadster.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "adventure",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/c284e07f-c119-4e30-8956-ce87ed3c8e1b_Triumph_Adventure.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "classic",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/64125b78-4c31-4009-9809-6b03d75f7883_Triumph_Classic.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "cruiser",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/cad7fa81-9526-4ee2-b7a1-2732540eaa4e_Triumph_Cruiser.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "motocross",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/b9d3cea5-bf65-4eb2-81d0-7d71a8657eb4_tf250x.jpg?auto=compress,format&rect=0,10,1100,607&w=435&h=240",
      },
    ],
    description: [
      "Few motorcycling brands can match the history and legacy of Triumph. Since the release of its very first motorcycle in 1902, Triumph has become an icon of British manufacturing. Bolstered by Hollywood legends such as Marlon Brando and Steve McQueen, few brands capture the essence of cool quite like Triumph. Following through with numerous land speed records and the largest-displacement engine in production (the 2294cc engine featured in the Triumph Rocket III), Triumph offers a riding experience unlike any other.",
      "Triumph produces everything from street bikes to cruisers, many of which have become instant design classics. At Bikebiz, we stock Triumph motorcycles to suit a range of tastes and budgets. With massive touring motorcycles, dual-sport motorcycles, and classic racers such as the Bonneville to choose from, you can find the perfect Triumph motorcycle for your needs at Bikebiz. We also stock a wide range of Triumph parts and accessories, making Bikebiz your one-stop shop for absolutely everything Triumph.",
      "Shop with Bikebiz for both trusted advice and the peace of mind that comes with buying your new Triumph from Australia’s motorcycle experts. We offer Australia-wide delivery to cities including Sydney, Melbourne, Brisbane, Adelaide, Perth, Hobart and more, with free shipping available for orders over $90. Check out our full range of Triumph motorcycles online, or visit your local Bikebiz store today to browse and buy with confidence!",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/f8c1fee0b75eb760d47a409979d7791ce2cbbd28_2019_triumph_scrambler_1200_xc_lifestyle1_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/f8c1fee0b75eb760d47a409979d7791ce2cbbd28_2019_triumph_scrambler_1200_xc_lifestyle1_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/6120234957a17006c1e3a7bc44ffa78529eccf6a_2019_triumph_speed_twin_lifestyle2_bikebiz.jpg?auto=compress,format",
    ],
  },
  aprilia: {
    logo: {
      src: "/brand-logo-aprilia.svg",
      width: 150,
      height: 154,
    },
    name: "Aprilia",
    categories: [
      {
        name: "supersport",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/a57fe5aa-da26-4862-b989-5c07922c77f2_rsv4.jpg?auto=compress,format&rect=0,0,999,551&w=435&h=240",
      },
      {
        name: "naked",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/10f5da98-a6e4-46c5-90ca-48cdf84cdf8a_tuono.jpg?auto=compress,format&rect=0,0,999,551&w=435&h=240",
      },
      {
        name: "adventure",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/1cc81303-c312-4257-9626-1e32b0aedab6_Taureg.jpg?auto=compress,format&rect=0,0,999,551&w=435&h=240",
      },
    ],
    description: [
      "From humble beginnings as a bicycle factory in small-town Italy, Aprilia has outsped the competition to become one of the top names in motorcycle racing today. Their focus on style, innovation and technology has allowed Aprilia to punch far above their weight, winning more World Championship Grand Prix titles than any other European brand. After being acquired by Piaggio in 2004, Aprilia has continued to produce motorbikes and scooters that uphold the ethos and legacy of Italian motorcycling.",
      "Aprilia’s product range includes high-octane sport motorcycles, scooters, naked motorcycles, and off-road motorbikes. At Bikebiz, we’re excited to stock a wide range of Aprilia products, from medium-capacity sports bikes to world-class superbikes like the RSV4. Our passionate team members have an extensive knowledge of Aprilia motorcycles and can help you select the perfect motorbike or scooter for your needs.",
      "Shop with Bikebiz for expert advice, helpful tips, and a thorough inspection from our workshop experts, who will ensure that your Aprilia motorcycle meets the highest standards of safety and performance before leaving our store. We can also arrange transport and delivery to cities across Australia, including Melbourne, Sydney, Brisbane, Adelaide, Perth, Hobart and more. Browse our collection of Aprilia motorcycles online, or visit us in store and ride away, hassle free, with Bikebiz.",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/2430542552a939be56a6333176107aaed0917f29_shiver_900_cruscotto.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/6ef7f729eb09c1a0859f0608fc3f2f564e26a119_rsv4-rf-image1.jpg?auto=compress,format",
    ],
  },
  kymco: {
    logo: {
      src: "/brand-logo-kymco.svg",
      width: 150,
      height: 154,
    },
    name: "Kymco",
    categories: [
      {
        name: "scooter",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/ca2912592568d43b8bde9d5da920cfb7104dde9e_ak550_homepage.jpg?auto=compress,format",
      },
    ],
    description: [
      "In just a few short years, Kymco has inspired a new generation of riders. Emerging from the technology and expertise behind Honda, Kymco offers a range of scooters, motorcycles and ATVs with a build quality that seems to defy their modest price tags. After taking its domestic market by storm, the Taiwanese powerhouse has grown to become the fifth largest scooter manufacturer in the world. Recent partnerships with BMW and Kawasaki have helped to establish Kymco as one of the most exciting new names in motorcycling.",
      "Although most famous for their scooters, Kymco’s product range also includes sport motorcycles, ATVs, roadster motorbikes, electric scooters and more. In every category, Kymco consistently delivers excellent engines and incredible value. We are pleased to offer a range of Kymco products at Bikebiz, from sporty scooters to stylish urban commuters. View our full range of Kymco scooters and motorcycles online, or visit your local Bikebiz store to browse and buy with confidence.",
      "Shop with Bikebiz for both trusted advice and the peace of mind that comes with buying a Kymco from Australia’s motorcycling experts. We also offer Australia-wide delivery to cities including Sydney, Melbourne, Brisbane, Adelaide, Perth, Hobart and more, with free shipping on orders over $90.",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/88ab5be73990ad4a07e6e73f9591c5fb663e94d6_agilityrs125_homepage.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/88ab5be73990ad4a07e6e73f9591c5fb663e94d6_agilityrs125_homepage.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/d71ead4d03f723eaf1a90734a62e45c2217ef8a2_like150_homepage.jpg?auto=compress,format",
    ],
  },
  suzuki: {
    logo: {
      src: "/brand-logo-suzuki.svg",
      width: 150,
      height: 154,
    },
    name: "Suzuki",
    categories: [
      {
        name: "naked",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        iamge:
          "https://images.prismic.io/bikebiz/4bba36ba-0c7e-409c-85bd-13c7bc3afb19_Suzuki_Naked.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "supersport",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/7f462088-bfad-411b-91a3-b21cf5ab58ce_Suzuki_Supersport.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "touring",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/4b156d87-9886-43af-bfff-23a6f6023eb3_Suzuki_Touring.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "scooter",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/9be85362-6cd8-406d-8d60-02ca872a86ba_Suzuki_Scooter.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "ensuro",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/5d91966b-f895-47e0-9fc7-c10e621a7e3b_Suzuki_Enduro.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "motoscross",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/fc58cf30-ec5b-43ef-8c2a-c5a919def539_Suzuki_Motocross.jpg?auto=compress,format&rect=0,0,1000,552&w=435&h=240",
      },
      {
        name: "agricultural",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/aefdb60e-9b4d-4115-be13-223db408c964_trojan+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "fun bike",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/3101b4f1-c70a-4651-b0f7-766d192a7f22_fun+bike.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
      {
        name: "quad",
        products: [
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: true,
          },
          {
            name: "Yamaha YZF-R1 Range",
            image:
              "https://images.prismic.io/bikebiz/5afe1b06-bf90-47b6-bae7-ba0ed7965dca_2022_YZF-R1M_BWM2_AUS_STU_003_750x600_v1.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
            isMark: false,
          },
        ],
        image:
          "https://images.prismic.io/bikebiz/9da99420-1efe-4aa1-8974-f3190c5bfced_quadsport+z90+2022.jpg?auto=compress,format&rect=0,9,1000,552&w=435&h=240",
      },
    ],
    description: [
      "In 1962, Suzuki catapulted into the world of two-stroke motorcycle racing. By using engineering secrets developed in East Germany, Suzuki became the first Japanese manufacturer to win a motocross world championship. Since then, Suzuki’s cutting-edge motorbikes have won racing championships in every discipline of two-wheel motorsports. Today, Suzuki motorcycles are known for their power, technology, and supreme reliability.",
      "Suzuki’s range includes some of the most advanced sport, super sport, and learner-approved bikes on the market. At Bikebiz, we stock Suzuki motorcycles to suit every kind of rider, from sport touring motorcycles like the GSX S1000 to the learner approved SV650X. We are also proud to offer a collection of Suzuki ATVs—each offering the technology, safety and performance you’d expect from a pioneer of ATV design and development.",
      "Shop with Bikebiz for both trusted advice and the peace of mind that comes with buying your Suzuki from Australia’s motorcycle experts. We offer Australia-wide delivery to cities including Sydney, Melbourne, Brisbane, Adelaide, Perth, Hobart and more, with free shipping available for orders over $90. Check out our range of Suzuki motorcycles online, or visit your local Bikebiz store today to browse and buy with confidence!",
    ],
    slideImages: [
      "https://images.prismic.io/bikebiz/16c8ab095f36e61f94ab723fdf92a34b92c147c5_suzuki_v-strom_1000_lifestyle1_bikebiz.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/b841e578d1acf9e52da608c79061d5ed587c379a_suzuki_gsxr1000_homepage.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/b841e578d1acf9e52da608c79061d5ed587c379a_suzuki_gsxr1000_homepage.jpg?auto=compress,format",
      "https://images.prismic.io/bikebiz/b841e578d1acf9e52da608c79061d5ed587c379a_suzuki_gsxr1000_homepage.jpg?auto=compress,format",
    ],
  },
};

export const fitments = [
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 2",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 3",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 4",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 5",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 6",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 7",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha - 2023 - model 8",
  },

  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 2",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 3",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 4",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 5",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 6",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha - 2022 - model 7",
  },

  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha - 2021 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha - 2021 - model 2",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha - 2021 - model 3",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha - 2021 - model 4",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha - 2021 - model 5",
  },
  {
    brand: "Yamaha",
    year: "2020",
    model: "Yamaha - 2020 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2019",
    model: "Yamaha - 2019 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2018",
    model: "Yamaha - 2018 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2017",
    model: "Yamaha - 2017 - model 1",
  },
  {
    brand: "Yamaha",
    year: "2016",
    model: "Yamaha - 2016 - model 1",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 1",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 2",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 3",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 4",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 5",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 6",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 7",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda - 2023 - model 8",
  },

  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 1",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 2",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 3",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 4",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 5",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 6",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda - 2022 - model 7",
  },

  {
    brand: "Honda",
    year: "2021",
    model: "Honda - 2021 - model 1",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda - 2021 - model 2",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda - 2021 - model 3",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda - 2021 - model 4",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda - 2021 - model 5",
  },
  {
    brand: "Honda",
    year: "2020",
    model: "Honda - 2020 - model 1",
  },
  {
    brand: "Honda",
    year: "2019",
    model: "Honda - 2019 - model 1",
  },
  {
    brand: "Honda",
    year: "2018",
    model: "Honda - 2018 - model 1",
  },
  {
    brand: "Honda",
    year: "2017",
    model: "Honda - 2017 - model 1",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 1",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 2",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 3",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 4",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 5",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 6",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 7",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki - 2023 - model 8",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 1",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 2",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 3",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 4",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 5",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 6",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki - 2022 - model 7",
  },

  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki - 2021 - model 1",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki - 2021 - model 2",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki - 2021 - model 3",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki - 2021 - model 4",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki - 2021 - model 5",
  },
  {
    brand: "Suzuki",
    year: "2020",
    model: "Suzuki - 2020 - model 1",
  },
  {
    brand: "Suzuki",
    year: "2019",
    model: "Suzuki - 2019 - model 1",
  },
  {
    brand: "Suzuki",
    year: "2018",
    model: "Suzuki - 2018 - model 1",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 1",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 2",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 3",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 4",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 5",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 6",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 7",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph - 2023 - model 8",
  },

  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 1",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 2",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 3",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 4",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 5",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 6",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph - 2022 - model 7",
  },

  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph - 2021 - model 1",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph - 2021 - model 2",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph - 2021 - model 3",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph - 2021 - model 4",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph - 2021 - model 5",
  },
  {
    brand: "Triumph",
    year: "2020",
    model: "Triumph - 2020 - model 1",
  },
  {
    brand: "Triumph",
    year: "2019",
    model: "Triumph - 2019 - model 1",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 1",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 2",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 3",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 4",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 5",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 6",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 7",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW - 2023 - model 8",
  },

  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 1",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 2",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 3",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 4",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 5",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 6",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW - 2022 - model 7",
  },

  {
    brand: "BMW",
    year: "2021",
    model: "BMW - 2021 - model 1",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW - 2021 - model 2",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW - 2021 - model 3",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW - 2021 - model 4",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW - 2021 - model 5",
  },
  {
    brand: "BMW",
    year: "2020",
    model: "BMW - 2020 - model 1",
  },
  {
    brand: "BMW",
    year: "2019",
    model: "BMW - 2019 - model 1",
  },
  {
    brand: "BMW",
    year: "2018",
    model: "BMW - 2018 - model 1",
  },
  {
    brand: "BMW",
    year: "2017",
    model: "BMW - 2017 - model 1",
  },
];

export const aboutContentData = [
  {
    title: "BIKEBIZ PARRAMATTA",
    list: [
      {
        title: "About Bikebiz Parramatta",
        slug: "bikebiz-parramatta",
        paragraphs: [
          `<p class="paragraph">Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.</p>`,
          `<p class="paragraph">Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.</p>`,
          `<p class="paragraph">With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.</p>`,
          `<p class="paragraph">Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.</p>`,
          `<p class="paragraph">With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.</p>`,
          `<p class="paragraph">Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.</p>`,
          `<p class="paragraph">Motorcycles: (02) 88 300 500</p>`,
          `<p class="paragraph">Accessories & Spares: (02) 8830 0550</p>`,
          `<p class="paragraph">Service Workshop: (02) 8830 0511</p>`,
        ],
      },
      {
        title: "Contact Bikebiz Parramatta",
        slug: "contact-bikebiz-parramatta",
        paragraphs: [
          `<p class="heading2">Bikebiz Parramatta - Yamaha, Suzuki, Aprilia & Kymco</p>`,
          `<p class="paragraph"></p>`,
          `<p class="heading3">Location: 7 Great Western Highway, Parramatta NSW 2150</p>`,
          `<p class="paragraph"></p>`,
          `<p class="paragraph">Contact Numbers:</p>`,
          `<div>
            <li class="listText">Motorcycles: (02) 88 300 500</li>
            <li class="listText">Accessories & Spares: (02) 8830 0550</li>
            <li class="listText">Workshop: (02) 8830 0511</li>
          </div>`,

          `<p class="paragraph">Trading Hours:</p>`,
          `<div>
            <li class="listText">Monday - Friday: 8:30am - 5:00pm</li>
            <li class="listText">Saturday: 8:30am - 4:00pm</li>
            <li class="listText">Sunday: Closed</li>
          </div>`,
          `<p class="paragraph">Valosi Pty Ltd T/A Bikebiz | ABN: 79 003 370 492</p>`,
          `<p class="paragraph">Motor Dealers Licence Number: DL 11002 | Motor Vehicles Repairers Licence Number: 29502</p>`,
        ],
      },
      {
        title: "New & Used Motorcycles at Bikebiz Parramatta",
        slug: "dealership-bikebiz-parramatta",
        paragraphs: [],
      },
      {
        title: "Workshop & Service at Bikebiz Parramatta",
        slug: "service-bikebiz-parramatta",
        paragraphs: [
          `<p class="paragraph">Bikebiz is one of the largest and longstanding motorcycle service centres in Sydney. Catering for all your motorcycle repair and service requirements.</p>`,
          `<p class="paragraph">At Bikebiz we pride ourselves on providing top customer service.</p>`,
          `<p class="paragraph">With fully qualified and manufacturer trained technicians, no job is too big or too small.</p>`,
          `<p class="paragraph">Our facilities are fitted with state of the art equipment and manufacturers special tools.</p>`,
          `<p class="paragraph">We also have the latest in manufacturers diagnostic equipments.</p>`,
          `<p class="paragraph">Our team offers: </p>`,
          `<div>
              <li class="listText">Log book servicing;</li>
              <li class="listText">Manufacturer recall repairs;</li>
              <li class="listText">Service inclusive;</li>
              <li class="listText">Loan bikes available;</li>
              <li class="listText">Warranty repairs;</li>
              <li class="listText">Tyre fitting;</li>
              <li class="listText">Accessories fitting;</li>
              <li class="listText">Pre purchase inspections;</li>
              <li class="listText">Rego inspections;</li>
              <li class="listText">Custom modifications;</li>
              <li class="listText">Full engine rebuilds and overhauls;</li>
              <li class="listText">Insurance repairs;</li>
              <li class="listText">Plenty more!</li>
            </div>`,
          `<p class="paragraph">Bikebiz is also a preferred repairer for all major insurance companies and can perform all your insurance claim assessments and repairs.</p>`,
          `<p class="paragraph">We use genuine parts & recommend only top quality products.</p>`,
          `<p class="paragraph">For further information please contact our friendly service team for assistance. </p>`,
          `<a class="paragraph link" href="https://bikebiz.typeform.com/to/vHC2ZS">Book a Service Now</a>`,
          `<p class="paragraph"></p>`,
        ],
      },
      {
        title: "Spares & Accessories at Bikebiz Parramatta",
        slug: "accessories-bikebiz-parramatta",
        paragraphs: [],
      },
    ],
  },
  {
    title: "BIKEBIZ GRANVILLE",
    list: [
      {
        title: "About Bikebiz Granville",
        slug: "bikebiz-granville",
        paragraphs: [
          `<p class="paragraph">Located at 274 Parramatta Road, Bikebiz Granville is your one-stop shop for quality motorcycles, parts, accessories and servicing. Bikebiz Granville is a proud dealer of Kawasaki, BMW, Honda and Triumph motorcycles. Come in store to browse our full range of new and used motorcycles and find the perfect motorbike for your needs. </p>`,
          `<p class="paragraph">Visit our Granville store today and talk to one of our friendly experts for professional help and advice. With years of experience and a passion for all things motorcycling, we can help you choose the best products for your lifestyle, and learn how to keep your bike and gear in top condition. We also stock an extensive range of motorcycle parts and accessories, so you can find everything you need to hit the road in comfort, safety and style. </p>`,
          `<p class="paragraph">Our Granville service centre also performs professional servicing and repairs for Kawasaki, Triumph, BMW and Honda motorcycles. We use the highest quality motorcycle parts and lubricants to ensure that all our motorcycles and scooters receive the best treatment possible. With industry-leading facilities and our team of highly trained technicians, you can enjoy the peace of mind that comes with leaving your motorbike with Australia’s leading motorcycle experts.</p>`,
          `<p class="paragraph">Bikebiz Granville is open from 9:00am – 5:00pm on weekdays, and between 9:00am – 4pm on Saturdays. Visit or call us today to receive friendly and professional advice on absolutely everything motorcycling. </p>`,
          `<p class="paragraph">Motorcycles: (02) 9682 2999</p>`,
          `<p class="paragraph">Accessories & Spares: (02) 9682 2999</p>`,
          `<p class="paragraph">Service Workshop: (02) 9682 2916</p>`,
        ],
      },
      {
        title: "Contact Bikebiz Granville",
        slug: "contact-bikebiz-granville",
        paragraphs: [
          `<p class="heading2">BIKEBIZ GRANVILLE - KAWASAKI, TRIUMPH, HONDA & BMW MOTORRAD</p>`,
          `<p class="paragraph"></p>`,
          `<p class="heading3">LOCATION: 274 PARRAMATTA RD, GRANVILLE NSW, 2142</p>`,
          `<p class="paragraph"></p>`,
          `<p class="paragraph">Contact Numbers:</p>`,
          `<div>
            <li class="listText">Motorcycles: (02) 9682 2999</li>
            <li class="listText">
            Accessories & Spares: (02) 9682 2999</li>
            <li class="listText">Workshop: (02) 9682 2916</li>
          </div>`,

          `<p class="paragraph">Trading Hours:</p>`,
          `<div>
            <li class="listText">Monday - Friday: 8:30am - 5:00pm</li>
            <li class="listText">Saturday: 8:30am - 4:00pm</li>
            <li class="listText">Sunday: Closed</li>
          </div>`,
          `<p class="paragraph">South Parramatta Motorcycles P/L T/A Bikebiz Kawasaki Triumph | ABN: 92 129 571 008</p>`,
          `<p class="paragraph">Motor Dealers Licence Number: DL 20882 | Motor Vehicles Repairers License Number: 45324</p>`,
        ],
      },
      {
        title: "New & Used Motorcycles at Bikebiz Granville",
        slug: "dealership-bikebiz-granville",
        paragraphs: [],
      },
      {
        title: "Workshop & Service at Bikebiz Granville",
        slug: "service-bikebiz-granville",
        paragraphs: [
          `<p class="paragraph">Bikebiz is one of the largest and longstanding motorcycle service centres in Sydney. Catering for all your motorcycle repair and service requirements.</p>`,
          `<p class="paragraph">At Bikebiz we pride ourselves on providing top customer service.</p>`,
          `<p class="paragraph">With fully qualified and manufacturer trained technicians, no job is too big or too small.</p>`,
          `<p class="paragraph">Our facilities are fitted with state of the art equipment and manufacturers special tools.</p>`,
          `<p class="paragraph">We also have the latest in manufacturers diagnostic equipments.</p>`,
          `<p class="paragraph">Our team offers: </p>`,
          `<div>
              <li class="listText">Log book servicing;</li>
              <li class="listText">Manufacturer recall repairs;</li>
              <li class="listText">Service inclusive;</li>
              <li class="listText">Loan bikes available;</li>
              <li class="listText">Warranty repairs;</li>
              <li class="listText">Tyre fitting;</li>
              <li class="listText">Accessories fitting;</li>
              <li class="listText">Pre purchase inspections;</li>
              <li class="listText">Rego inspections;</li>
              <li class="listText">Custom modifications;</li>
              <li class="listText">Full engine rebuilds and overhauls;</li>
              <li class="listText">Insurance repairs;</li>
              <li class="listText">Plenty more!</li>
            </div>`,
          `<p class="paragraph">Bikebiz is also a preferred repairer for all major insurance companies and can perform all your insurance claim assessments and repairs.</p>`,
          `<p class="paragraph">We use genuine parts & recommend only top quality products.</p>`,
          `<p class="paragraph">For further information please contact our friendly service team for assistance. </p>`,
          `<a class="paragraph link" href="https://bikebiz.typeform.com/to/vHC2ZS">Book a Service Now</a>`,
          `<p class="paragraph"></p>`,
        ],
      },
      {
        title: "Spares & Accessories at Bikebiz Granville",
        slug: "accessories-bikebiz-granville",
        paragraphs: [],
      },
    ],
  },
  {
    title: "BIKEBIZ ONLINE",
    list: [
      {
        title: "Contact Bikebiz Online",
        slug: "contact-bikebiz-online",
        paragraphs: [
          `<p class="heading2">BIKEBIZ ONLINE - ONLINE ORDER ENQUIRIES</p>`,
          `<p class="paragraph">Phone: 1300 250 450</p>`,
          `<p class="paragraph">Bikebiz Online is closed Saturday and Sunday.</p>`,
        ],
      },
      {
        title: "Bikebiz Online Returns Policy",
        slug: "returns-policy",
        paragraphs: [
          `<p class="heading2">BIKEBIZ ONLINE - ONLINE ORDER ENQUIRIES</p>`,
          `<p class="paragraph">Phone: 1300 250 450</p>`,
          `<p class="paragraph">Bikebiz Online is closed Saturday and Sunday.</p>`,
        ],
      },
      {
        title: "Bikebiz Online Delivery Policy",
        slug: "delivery-policy",
        paragraphs: [
          "Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.",
          "Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.",
          "With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.",
          "Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.",
          "With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.",
          "Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.",
          "Motorcycles: (02) 88 300 500",
          "Accessories & Spares: (02) 8830 0550",
          "Service Workshop: (02) 8830 0511",
        ],
      },
      {
        title: "Bikebiz & Afterpay",
        slug: "bikebiz-afterpay",
        paragraphs: [
          "Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.",
          "Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.",
          "With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.",
          "Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.",
          "With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.",
          "Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.",
          "Motorcycles: (02) 88 300 500",
          "Accessories & Spares: (02) 8830 0550",
          "Service Workshop: (02) 8830 0511",
        ],
      },
      {
        title: "Size Guides",
        slug: "size-guides",
        paragraphs: [
          "Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.",
          "Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.",
          "With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.",
          "Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.",
          "With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.",
          "Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.",
          "Motorcycles: (02) 88 300 500",
          "Accessories & Spares: (02) 8830 0550",
          "Service Workshop: (02) 8830 0511",
        ],
      },
    ],
  },
  {
    title: "GENERAL INFORMATION",
    list: [
      {
        title: "Sell Your Motorcycle to Bikebiz",
        slug: "sell-your-motorcycle",
        paragraphs: [
          "Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.",
          "Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.",
          "With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.",
          "Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.",
          "With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.",
          "Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.",
          "Motorcycles: (02) 88 300 500",
          "Accessories & Spares: (02) 8830 0550",
          "Service Workshop: (02) 8830 0511",
        ],
      },
    ],
  },
  {
    title: "CAREERS AT BIKEBIZ",
    list: [
      {
        title: "Current Available Positions",
        slug: "careers-at-bikebiz",
        paragraphs: [
          "Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.",
          "Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.",
          "With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.",
          "Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.",
          "With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.",
          "Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.",
          "Motorcycles: (02) 88 300 500",
          "Accessories & Spares: (02) 8830 0550",
          "Service Workshop: (02) 8830 0511",
        ],
      },
    ],
  },
  {
    title: "LEGAL",
    list: [
      {
        title: "Bikebiz Website Privacy Policy",
        slug: "privacy-policy",
        paragraphs: [
          "Located just behind Parramatta train station on the Great Western Highway, Bikebiz Parramatta is your one-stop shop for all your motorcycle needs. Bikebiz Parramatta is a proud dealer of Yamaha, Aprilia and Kymco motorcycles; browse our wide range of new and used motorcycles to find the perfect bike for your needs.",
          "Over the past 14 years, Bikebiz Parramatta has become Australia’s leading dealer of Yamaha motorcycles. From dirt bikes and scooters to high-octane rockstars like the XVS1300A, our team of passionate Yamaha experts can help you choose the best motorcycle for your budget and tastes.",
          "With humble beginnings as a bicycle factory in small-town Italy, Aprilia has become the sporting flagship of the Piaggio Group. Bikebiz Parramatta is proud to continue the legacy of Italian manufacturing and motorcycling with our collection of new and used Aprilia motorcycles. Visit us in store to browse the full range of Aprilia motorcycles.",
          "Since splitting from the Honda Motor Company, Kymco has taken the world by storm with its range of affordable scooters, motorcycles, and all-terrain vehicles. Come and talk to one of our experts to discover why Kymco has become one of the fastest-growing motorcycling brands in the world.",
          "With an extensive range of motorcycle parts and accessories, our Parramatta store has everything you need to hit the road in comfort, safety and style. Our motorcycle service centre also offers professional servicing and repairs for Yamaha, Aprilia and Kymco motorcycles. With cutting-edge facilities and a team of highly trained technicians, you can enjoy the peace of mind that comes with trusting your bike to Australia’s leading motorcycle experts.",
          "Bikebiz Parramatta is open from 9:00am – 5:00pm on weekdays, and from 9:00am – 4pm on Saturdays. If you have any questions, simply call us for friendly and professional advice.",
          "Motorcycles: (02) 88 300 500",
          "Accessories & Spares: (02) 8830 0550",
          "Service Workshop: (02) 8830 0511",
        ],
      },
    ],
  },
];

export const blogPosts = [
  {
    title: "Adventure Time | Triumph Tiger Sport 660",
    type: "article",
    image:
      "https://images.prismic.io/bikebizecom/5894f40e-2f7d-4112-a397-7e28eab7cbac_Tiger_Sport_660__0O6A3421_PB.jpg?auto=compress,format",
    slug: "triumph-tiger-sport-660",
    category: "bikebiz race team",
    author: "Kym Liebig",
    date: "2021/11/10",
    content: [
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong>ADVENTURE TIME – TRIUMPH’S TIGER SPORT 660 AT A GLANCE</strong></p>
        <p class="paragraph">Not to sound too much like Grandpa Simpson, but bloody hell the LAMS crowd have got it good these days. Brilliant bikes as far as the eye can see. Lots of choice, tons of style and feature-rich rides at perfectly reasonable prices.</p>
        <p class="paragraph">The latest entry that’s set to turn the LAMS kids (Okay, I know, they’re not all kids…) into a bunch of spoiled brats is the Triumph Tiger Sport 660. When Triumph first took off in the ADV direction with their Tiger range it seemed an unlikely excursion, but their adventurous bikes quickly gained a reputation for quality, performance and features. At first blush their built-for-beginners 660 looks like more of the same, so let’s crack into it.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Who’s it for?</em></strong></p>
        <p class="paragraph">Let’s set aside the Paris Dakar stuff for a sec. If you’re a new rider, but small bikes make you look like a monkey molesting a football, the Tiger Sport 660 is worth a look right off the bat. Tall enough without being towering (seat height is 835mm), there’s plenty of room for larger folk to get comfortable on the Tiger. Seating is nice and upright too, so seeing over hatchbacks is less of a problem than it might be on a sports bike.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/b1b61e14-8cce-4f92-8c8e-7acf6da0a5df_DSC_8423.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/e416c3a7-d005-41b4-a91b-1939596efccc_4Qm1qQ8k.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/c06f75a9-1855-465a-88ce-491d553ab237_DSC_8430.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/7fbe76c1-1e75-40db-8b9e-2c64ee9674c3_DSC_8950.JPG?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph">Hang on, hatchbacks? Isn’t this an adventure bike? Well, yes and no. Chassis, suspension and riding position say yes. Alloy wheels and road tyres say less so. The potholed goat tracks we refer to as ‘roads’ in Australia mean that the long-legged, long travel, upright form factor makes more and more sense when tackling commuting, a bit of sporty riding or just a weekend escape. If you’re seriously out to ride unsealed roads and single-track stuff, you might be better off looking for something with spoked wheels and knobby tyres.</p>
        `,
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>What makes it different? Some engine torque…</em></strong></p>
          <p class="paragraph">It’s a Triumph, so if you can hold up three fingers, there’s your cylinder count. Triumph’s triples have a lovely, torquey character, a smooth delivery but a satisfying howl up at the top of the rev range. The predictable power curve is spot-on for new riders, the engine cranking out a LAMS-friendly 35kW at just under 9,000rpm and 59Nm of torque way down at 5,250rpm. That’s handy, and the distinctive three-cylinder exhaust note also makes it mighty entertaining. If you prefer your motorcycle engines not to sound like kitchen appliances, you’re going to like this one.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>Handling and brakes</em></strong></p>
          <p class="paragraph">Triumph have set out to create the benchmark for handling in the ‘middleweight adventure sports’ category and on paper they seem to have a winner. Up front there are 41mm Showa upside down cartridge forks and at the rear, a Showa monoshock with remote hydraulic preload adjustment – a handy feature on a bike designed with luggage and pillions in mind. Stoppers at the front are twin 310mm discs chomped by Nissin calipers, and of course standard ABS. Tyres are Michelin Road 5’s for a nice mix of comfort, grip and durability.</p>
          <p class="paragraph">Of course a good measure of handling springs from chassis design, and here Triumph are drawing on their Tiger track record of sweet, neutral handling bikes with a trustworthy feel, yet a good serve of agility.</p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Other stuff that matters</em></strong></p>
        <p class="paragraph">Style? Triumph score big points here, bringing on a smooth blend of ADV-meets-sporting sleekness. For those who prefer streamlining and smooth lines in a bike rather than the ‘4WD’ look, there’s a lot to like. Every design feature is carefully thought out, nothing looks like an afterthought.&nbsp;</p>
        <p class="paragraph">Befitting its adventurous nature, the Tiger 660 has a generous 17-litre fuel tank, which ought to give decent range. The standard screen is height-adjustable with one hand, and tall enough to take the windblast off reasonably lofty folks. Seating is generous and comfy for two. Mounts are nicely integrated should you choose to grab a pair of accessory panniers.</p>
        <p class="paragraph">Should you wish to see the dessert menu, Triumph is standing by with no less than 40 dedicated accessories.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `

        <p class="paragraph"><strong><em>Would you like fries with that?&nbsp;</em></strong></p>
        <p class="paragraph">Okay, maybe not fries, but Triumph really have gone all-in to supersize the standard inclusions on the entry-level Tiger. There’s a serious swag of kit bolted to this package before we open up the options cattle dog, including:</p>
        <ul class="u-items"><li>Road and Rain Riding Modes</li><li>Full LED lighting and self-cancelling indicators</li><li>Multi-functional instruments with TFT display, ready to accept optional My Triumph connectivity</li><li>Switchable traction control</li><li>ABS (Did I already say that? Yes.)</li><li>Ride-by-wire throttle</li><li>Slip-and-assist clutch</li><li>Immobiliser</li></ul>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">I told you those damn kids are being spoiled! To top all of this off, the Tiger Sport 660 has lengthy 16,000 kay/12-month service intervals, claimed lowest workshop costs in its category and a rideaway price from $14,690. Mercy.</p>
        <p class="paragraph"></p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">Triumph’s Tiger 660 Sport Due to lob in Australian showrooms come early 2022. If you have an eye on the middle rung of the Adventure bike market, it’ll be worth a look for sure.</p>
        `,
      },
    ],
  },
  {
    title: "Evolution of a Favourite",
    type: "article",
    image:
      "https://images.prismic.io/bikebizecom/07602249-339d-48e3-beaa-3d00dc63c260_Shoei-NXR2_fb_cover-image.jpg?auto=compress,format",
    slug: "meet-the-shoei-nxr-2",
    category: "bikebiz race team",
    author: "Kym Liebig",
    date: "2021/11/10",
    content: [
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong>ADVENTURE TIME – TRIUMPH’S TIGER SPORT 660 AT A GLANCE</strong></p>
        <p class="paragraph">Not to sound too much like Grandpa Simpson, but bloody hell the LAMS crowd have got it good these days. Brilliant bikes as far as the eye can see. Lots of choice, tons of style and feature-rich rides at perfectly reasonable prices.</p>
        <p class="paragraph">The latest entry that’s set to turn the LAMS kids (Okay, I know, they’re not all kids…) into a bunch of spoiled brats is the Triumph Tiger Sport 660. When Triumph first took off in the ADV direction with their Tiger range it seemed an unlikely excursion, but their adventurous bikes quickly gained a reputation for quality, performance and features. At first blush their built-for-beginners 660 looks like more of the same, so let’s crack into it.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Who’s it for?</em></strong></p>
        <p class="paragraph">Let’s set aside the Paris Dakar stuff for a sec. If you’re a new rider, but small bikes make you look like a monkey molesting a football, the Tiger Sport 660 is worth a look right off the bat. Tall enough without being towering (seat height is 835mm), there’s plenty of room for larger folk to get comfortable on the Tiger. Seating is nice and upright too, so seeing over hatchbacks is less of a problem than it might be on a sports bike.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/b1b61e14-8cce-4f92-8c8e-7acf6da0a5df_DSC_8423.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/e416c3a7-d005-41b4-a91b-1939596efccc_4Qm1qQ8k.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/c06f75a9-1855-465a-88ce-491d553ab237_DSC_8430.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/7fbe76c1-1e75-40db-8b9e-2c64ee9674c3_DSC_8950.JPG?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph">Hang on, hatchbacks? Isn’t this an adventure bike? Well, yes and no. Chassis, suspension and riding position say yes. Alloy wheels and road tyres say less so. The potholed goat tracks we refer to as ‘roads’ in Australia mean that the long-legged, long travel, upright form factor makes more and more sense when tackling commuting, a bit of sporty riding or just a weekend escape. If you’re seriously out to ride unsealed roads and single-track stuff, you might be better off looking for something with spoked wheels and knobby tyres.</p>
        `,
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>What makes it different? Some engine torque…</em></strong></p>
          <p class="paragraph">It’s a Triumph, so if you can hold up three fingers, there’s your cylinder count. Triumph’s triples have a lovely, torquey character, a smooth delivery but a satisfying howl up at the top of the rev range. The predictable power curve is spot-on for new riders, the engine cranking out a LAMS-friendly 35kW at just under 9,000rpm and 59Nm of torque way down at 5,250rpm. That’s handy, and the distinctive three-cylinder exhaust note also makes it mighty entertaining. If you prefer your motorcycle engines not to sound like kitchen appliances, you’re going to like this one.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>Handling and brakes</em></strong></p>
          <p class="paragraph">Triumph have set out to create the benchmark for handling in the ‘middleweight adventure sports’ category and on paper they seem to have a winner. Up front there are 41mm Showa upside down cartridge forks and at the rear, a Showa monoshock with remote hydraulic preload adjustment – a handy feature on a bike designed with luggage and pillions in mind. Stoppers at the front are twin 310mm discs chomped by Nissin calipers, and of course standard ABS. Tyres are Michelin Road 5’s for a nice mix of comfort, grip and durability.</p>
          <p class="paragraph">Of course a good measure of handling springs from chassis design, and here Triumph are drawing on their Tiger track record of sweet, neutral handling bikes with a trustworthy feel, yet a good serve of agility.</p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Other stuff that matters</em></strong></p>
        <p class="paragraph">Style? Triumph score big points here, bringing on a smooth blend of ADV-meets-sporting sleekness. For those who prefer streamlining and smooth lines in a bike rather than the ‘4WD’ look, there’s a lot to like. Every design feature is carefully thought out, nothing looks like an afterthought.&nbsp;</p>
        <p class="paragraph">Befitting its adventurous nature, the Tiger 660 has a generous 17-litre fuel tank, which ought to give decent range. The standard screen is height-adjustable with one hand, and tall enough to take the windblast off reasonably lofty folks. Seating is generous and comfy for two. Mounts are nicely integrated should you choose to grab a pair of accessory panniers.</p>
        <p class="paragraph">Should you wish to see the dessert menu, Triumph is standing by with no less than 40 dedicated accessories.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `

        <p class="paragraph"><strong><em>Would you like fries with that?&nbsp;</em></strong></p>
        <p class="paragraph">Okay, maybe not fries, but Triumph really have gone all-in to supersize the standard inclusions on the entry-level Tiger. There’s a serious swag of kit bolted to this package before we open up the options cattle dog, including:</p>
        <ul class="u-items"><li>Road and Rain Riding Modes</li><li>Full LED lighting and self-cancelling indicators</li><li>Multi-functional instruments with TFT display, ready to accept optional My Triumph connectivity</li><li>Switchable traction control</li><li>ABS (Did I already say that? Yes.)</li><li>Ride-by-wire throttle</li><li>Slip-and-assist clutch</li><li>Immobiliser</li></ul>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">I told you those damn kids are being spoiled! To top all of this off, the Tiger Sport 660 has lengthy 16,000 kay/12-month service intervals, claimed lowest workshop costs in its category and a rideaway price from $14,690. Mercy.</p>
        <p class="paragraph"></p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">Triumph’s Tiger 660 Sport Due to lob in Australian showrooms come early 2022. If you have an eye on the middle rung of the Adventure bike market, it’ll be worth a look for sure.</p>
        `,
      },
    ],
  },
  {
    title: "RIDING YAMAHA’S R7 – THE CURE FOR SUPERBIKE SICKNESS?",
    type: "article",
    image: "https://images.prismic.io/bikebizecom/2936b15e-90d7-48a9-ae94-b950893eaec8_R7-6.jpg?auto=compress,format",
    slug: "yamaha-yzf-r7-in-depth-review",
    category: "motorcycles",
    author: "Kym Liebig",
    date: "2021/11/10",
    content: [
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong>ADVENTURE TIME – TRIUMPH’S TIGER SPORT 660 AT A GLANCE</strong></p>
        <p class="paragraph">Not to sound too much like Grandpa Simpson, but bloody hell the LAMS crowd have got it good these days. Brilliant bikes as far as the eye can see. Lots of choice, tons of style and feature-rich rides at perfectly reasonable prices.</p>
        <p class="paragraph">The latest entry that’s set to turn the LAMS kids (Okay, I know, they’re not all kids…) into a bunch of spoiled brats is the Triumph Tiger Sport 660. When Triumph first took off in the ADV direction with their Tiger range it seemed an unlikely excursion, but their adventurous bikes quickly gained a reputation for quality, performance and features. At first blush their built-for-beginners 660 looks like more of the same, so let’s crack into it.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Who’s it for?</em></strong></p>
        <p class="paragraph">Let’s set aside the Paris Dakar stuff for a sec. If you’re a new rider, but small bikes make you look like a monkey molesting a football, the Tiger Sport 660 is worth a look right off the bat. Tall enough without being towering (seat height is 835mm), there’s plenty of room for larger folk to get comfortable on the Tiger. Seating is nice and upright too, so seeing over hatchbacks is less of a problem than it might be on a sports bike.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/b1b61e14-8cce-4f92-8c8e-7acf6da0a5df_DSC_8423.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/e416c3a7-d005-41b4-a91b-1939596efccc_4Qm1qQ8k.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/c06f75a9-1855-465a-88ce-491d553ab237_DSC_8430.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/7fbe76c1-1e75-40db-8b9e-2c64ee9674c3_DSC_8950.JPG?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph">Hang on, hatchbacks? Isn’t this an adventure bike? Well, yes and no. Chassis, suspension and riding position say yes. Alloy wheels and road tyres say less so. The potholed goat tracks we refer to as ‘roads’ in Australia mean that the long-legged, long travel, upright form factor makes more and more sense when tackling commuting, a bit of sporty riding or just a weekend escape. If you’re seriously out to ride unsealed roads and single-track stuff, you might be better off looking for something with spoked wheels and knobby tyres.</p>
        `,
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>What makes it different? Some engine torque…</em></strong></p>
          <p class="paragraph">It’s a Triumph, so if you can hold up three fingers, there’s your cylinder count. Triumph’s triples have a lovely, torquey character, a smooth delivery but a satisfying howl up at the top of the rev range. The predictable power curve is spot-on for new riders, the engine cranking out a LAMS-friendly 35kW at just under 9,000rpm and 59Nm of torque way down at 5,250rpm. That’s handy, and the distinctive three-cylinder exhaust note also makes it mighty entertaining. If you prefer your motorcycle engines not to sound like kitchen appliances, you’re going to like this one.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>Handling and brakes</em></strong></p>
          <p class="paragraph">Triumph have set out to create the benchmark for handling in the ‘middleweight adventure sports’ category and on paper they seem to have a winner. Up front there are 41mm Showa upside down cartridge forks and at the rear, a Showa monoshock with remote hydraulic preload adjustment – a handy feature on a bike designed with luggage and pillions in mind. Stoppers at the front are twin 310mm discs chomped by Nissin calipers, and of course standard ABS. Tyres are Michelin Road 5’s for a nice mix of comfort, grip and durability.</p>
          <p class="paragraph">Of course a good measure of handling springs from chassis design, and here Triumph are drawing on their Tiger track record of sweet, neutral handling bikes with a trustworthy feel, yet a good serve of agility.</p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Other stuff that matters</em></strong></p>
        <p class="paragraph">Style? Triumph score big points here, bringing on a smooth blend of ADV-meets-sporting sleekness. For those who prefer streamlining and smooth lines in a bike rather than the ‘4WD’ look, there’s a lot to like. Every design feature is carefully thought out, nothing looks like an afterthought.&nbsp;</p>
        <p class="paragraph">Befitting its adventurous nature, the Tiger 660 has a generous 17-litre fuel tank, which ought to give decent range. The standard screen is height-adjustable with one hand, and tall enough to take the windblast off reasonably lofty folks. Seating is generous and comfy for two. Mounts are nicely integrated should you choose to grab a pair of accessory panniers.</p>
        <p class="paragraph">Should you wish to see the dessert menu, Triumph is standing by with no less than 40 dedicated accessories.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `

        <p class="paragraph"><strong><em>Would you like fries with that?&nbsp;</em></strong></p>
        <p class="paragraph">Okay, maybe not fries, but Triumph really have gone all-in to supersize the standard inclusions on the entry-level Tiger. There’s a serious swag of kit bolted to this package before we open up the options cattle dog, including:</p>
        <ul class="u-items"><li>Road and Rain Riding Modes</li><li>Full LED lighting and self-cancelling indicators</li><li>Multi-functional instruments with TFT display, ready to accept optional My Triumph connectivity</li><li>Switchable traction control</li><li>ABS (Did I already say that? Yes.)</li><li>Ride-by-wire throttle</li><li>Slip-and-assist clutch</li><li>Immobiliser</li></ul>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">I told you those damn kids are being spoiled! To top all of this off, the Tiger Sport 660 has lengthy 16,000 kay/12-month service intervals, claimed lowest workshop costs in its category and a rideaway price from $14,690. Mercy.</p>
        <p class="paragraph"></p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">Triumph’s Tiger 660 Sport Due to lob in Australian showrooms come early 2022. If you have an eye on the middle rung of the Adventure bike market, it’ll be worth a look for sure.</p>
        `,
      },
    ],
  },
  {
    title: "Bikebiz Race Team heads to Phillip Island",
    type: "article",
    image:
      "https://images.prismic.io/bikebizecom/e91622a8-7162-4e72-bb08-4e248207f26d_thumbnail_274821066_4857157394368194_5345023366144712282_n.jpg?auto=compress,format",
    slug: "bikebiz-race-team-asbk-round-1",
    category: "on the tools",
    author: "Kym Liebig",
    date: "2021/11/10",
    content: [
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong>ADVENTURE TIME – TRIUMPH’S TIGER SPORT 660 AT A GLANCE</strong></p>
        <p class="paragraph">Not to sound too much like Grandpa Simpson, but bloody hell the LAMS crowd have got it good these days. Brilliant bikes as far as the eye can see. Lots of choice, tons of style and feature-rich rides at perfectly reasonable prices.</p>
        <p class="paragraph">The latest entry that’s set to turn the LAMS kids (Okay, I know, they’re not all kids…) into a bunch of spoiled brats is the Triumph Tiger Sport 660. When Triumph first took off in the ADV direction with their Tiger range it seemed an unlikely excursion, but their adventurous bikes quickly gained a reputation for quality, performance and features. At first blush their built-for-beginners 660 looks like more of the same, so let’s crack into it.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Who’s it for?</em></strong></p>
        <p class="paragraph">Let’s set aside the Paris Dakar stuff for a sec. If you’re a new rider, but small bikes make you look like a monkey molesting a football, the Tiger Sport 660 is worth a look right off the bat. Tall enough without being towering (seat height is 835mm), there’s plenty of room for larger folk to get comfortable on the Tiger. Seating is nice and upright too, so seeing over hatchbacks is less of a problem than it might be on a sports bike.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/b1b61e14-8cce-4f92-8c8e-7acf6da0a5df_DSC_8423.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/e416c3a7-d005-41b4-a91b-1939596efccc_4Qm1qQ8k.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/c06f75a9-1855-465a-88ce-491d553ab237_DSC_8430.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/7fbe76c1-1e75-40db-8b9e-2c64ee9674c3_DSC_8950.JPG?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph">Hang on, hatchbacks? Isn’t this an adventure bike? Well, yes and no. Chassis, suspension and riding position say yes. Alloy wheels and road tyres say less so. The potholed goat tracks we refer to as ‘roads’ in Australia mean that the long-legged, long travel, upright form factor makes more and more sense when tackling commuting, a bit of sporty riding or just a weekend escape. If you’re seriously out to ride unsealed roads and single-track stuff, you might be better off looking for something with spoked wheels and knobby tyres.</p>
        `,
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>What makes it different? Some engine torque…</em></strong></p>
          <p class="paragraph">It’s a Triumph, so if you can hold up three fingers, there’s your cylinder count. Triumph’s triples have a lovely, torquey character, a smooth delivery but a satisfying howl up at the top of the rev range. The predictable power curve is spot-on for new riders, the engine cranking out a LAMS-friendly 35kW at just under 9,000rpm and 59Nm of torque way down at 5,250rpm. That’s handy, and the distinctive three-cylinder exhaust note also makes it mighty entertaining. If you prefer your motorcycle engines not to sound like kitchen appliances, you’re going to like this one.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>Handling and brakes</em></strong></p>
          <p class="paragraph">Triumph have set out to create the benchmark for handling in the ‘middleweight adventure sports’ category and on paper they seem to have a winner. Up front there are 41mm Showa upside down cartridge forks and at the rear, a Showa monoshock with remote hydraulic preload adjustment – a handy feature on a bike designed with luggage and pillions in mind. Stoppers at the front are twin 310mm discs chomped by Nissin calipers, and of course standard ABS. Tyres are Michelin Road 5’s for a nice mix of comfort, grip and durability.</p>
          <p class="paragraph">Of course a good measure of handling springs from chassis design, and here Triumph are drawing on their Tiger track record of sweet, neutral handling bikes with a trustworthy feel, yet a good serve of agility.</p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Other stuff that matters</em></strong></p>
        <p class="paragraph">Style? Triumph score big points here, bringing on a smooth blend of ADV-meets-sporting sleekness. For those who prefer streamlining and smooth lines in a bike rather than the ‘4WD’ look, there’s a lot to like. Every design feature is carefully thought out, nothing looks like an afterthought.&nbsp;</p>
        <p class="paragraph">Befitting its adventurous nature, the Tiger 660 has a generous 17-litre fuel tank, which ought to give decent range. The standard screen is height-adjustable with one hand, and tall enough to take the windblast off reasonably lofty folks. Seating is generous and comfy for two. Mounts are nicely integrated should you choose to grab a pair of accessory panniers.</p>
        <p class="paragraph">Should you wish to see the dessert menu, Triumph is standing by with no less than 40 dedicated accessories.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `

        <p class="paragraph"><strong><em>Would you like fries with that?&nbsp;</em></strong></p>
        <p class="paragraph">Okay, maybe not fries, but Triumph really have gone all-in to supersize the standard inclusions on the entry-level Tiger. There’s a serious swag of kit bolted to this package before we open up the options cattle dog, including:</p>
        <ul class="u-items"><li>Road and Rain Riding Modes</li><li>Full LED lighting and self-cancelling indicators</li><li>Multi-functional instruments with TFT display, ready to accept optional My Triumph connectivity</li><li>Switchable traction control</li><li>ABS (Did I already say that? Yes.)</li><li>Ride-by-wire throttle</li><li>Slip-and-assist clutch</li><li>Immobiliser</li></ul>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">I told you those damn kids are being spoiled! To top all of this off, the Tiger Sport 660 has lengthy 16,000 kay/12-month service intervals, claimed lowest workshop costs in its category and a rideaway price from $14,690. Mercy.</p>
        <p class="paragraph"></p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">Triumph’s Tiger 660 Sport Due to lob in Australian showrooms come early 2022. If you have an eye on the middle rung of the Adventure bike market, it’ll be worth a look for sure.</p>
        `,
      },
    ],
  },
  {
    title: "The Day that never happened",
    type: "article",
    image:
      "https://images.prismic.io/bikebizecom/778ec242-b358-41e5-957c-028aadfdd8e9_%2326+Edwards+Tom+-Yamaha-ForemostMedia-Round+2+Australian+Superbike+Championship1586.jpg?auto=compress,format",
    slug: "the-day-that-never-happened",
    category: "parts & accessories",
    author: "Kym Liebig",
    date: "2021/11/10",
    content: [
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong>ADVENTURE TIME – TRIUMPH’S TIGER SPORT 660 AT A GLANCE</strong></p>
        <p class="paragraph">Not to sound too much like Grandpa Simpson, but bloody hell the LAMS crowd have got it good these days. Brilliant bikes as far as the eye can see. Lots of choice, tons of style and feature-rich rides at perfectly reasonable prices.</p>
        <p class="paragraph">The latest entry that’s set to turn the LAMS kids (Okay, I know, they’re not all kids…) into a bunch of spoiled brats is the Triumph Tiger Sport 660. When Triumph first took off in the ADV direction with their Tiger range it seemed an unlikely excursion, but their adventurous bikes quickly gained a reputation for quality, performance and features. At first blush their built-for-beginners 660 looks like more of the same, so let’s crack into it.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Who’s it for?</em></strong></p>
        <p class="paragraph">Let’s set aside the Paris Dakar stuff for a sec. If you’re a new rider, but small bikes make you look like a monkey molesting a football, the Tiger Sport 660 is worth a look right off the bat. Tall enough without being towering (seat height is 835mm), there’s plenty of room for larger folk to get comfortable on the Tiger. Seating is nice and upright too, so seeing over hatchbacks is less of a problem than it might be on a sports bike.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/b1b61e14-8cce-4f92-8c8e-7acf6da0a5df_DSC_8423.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/e416c3a7-d005-41b4-a91b-1939596efccc_4Qm1qQ8k.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/c06f75a9-1855-465a-88ce-491d553ab237_DSC_8430.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/7fbe76c1-1e75-40db-8b9e-2c64ee9674c3_DSC_8950.JPG?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph">Hang on, hatchbacks? Isn’t this an adventure bike? Well, yes and no. Chassis, suspension and riding position say yes. Alloy wheels and road tyres say less so. The potholed goat tracks we refer to as ‘roads’ in Australia mean that the long-legged, long travel, upright form factor makes more and more sense when tackling commuting, a bit of sporty riding or just a weekend escape. If you’re seriously out to ride unsealed roads and single-track stuff, you might be better off looking for something with spoked wheels and knobby tyres.</p>
        `,
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>What makes it different? Some engine torque…</em></strong></p>
          <p class="paragraph">It’s a Triumph, so if you can hold up three fingers, there’s your cylinder count. Triumph’s triples have a lovely, torquey character, a smooth delivery but a satisfying howl up at the top of the rev range. The predictable power curve is spot-on for new riders, the engine cranking out a LAMS-friendly 35kW at just under 9,000rpm and 59Nm of torque way down at 5,250rpm. That’s handy, and the distinctive three-cylinder exhaust note also makes it mighty entertaining. If you prefer your motorcycle engines not to sound like kitchen appliances, you’re going to like this one.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>Handling and brakes</em></strong></p>
          <p class="paragraph">Triumph have set out to create the benchmark for handling in the ‘middleweight adventure sports’ category and on paper they seem to have a winner. Up front there are 41mm Showa upside down cartridge forks and at the rear, a Showa monoshock with remote hydraulic preload adjustment – a handy feature on a bike designed with luggage and pillions in mind. Stoppers at the front are twin 310mm discs chomped by Nissin calipers, and of course standard ABS. Tyres are Michelin Road 5’s for a nice mix of comfort, grip and durability.</p>
          <p class="paragraph">Of course a good measure of handling springs from chassis design, and here Triumph are drawing on their Tiger track record of sweet, neutral handling bikes with a trustworthy feel, yet a good serve of agility.</p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Other stuff that matters</em></strong></p>
        <p class="paragraph">Style? Triumph score big points here, bringing on a smooth blend of ADV-meets-sporting sleekness. For those who prefer streamlining and smooth lines in a bike rather than the ‘4WD’ look, there’s a lot to like. Every design feature is carefully thought out, nothing looks like an afterthought.&nbsp;</p>
        <p class="paragraph">Befitting its adventurous nature, the Tiger 660 has a generous 17-litre fuel tank, which ought to give decent range. The standard screen is height-adjustable with one hand, and tall enough to take the windblast off reasonably lofty folks. Seating is generous and comfy for two. Mounts are nicely integrated should you choose to grab a pair of accessory panniers.</p>
        <p class="paragraph">Should you wish to see the dessert menu, Triumph is standing by with no less than 40 dedicated accessories.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `

        <p class="paragraph"><strong><em>Would you like fries with that?&nbsp;</em></strong></p>
        <p class="paragraph">Okay, maybe not fries, but Triumph really have gone all-in to supersize the standard inclusions on the entry-level Tiger. There’s a serious swag of kit bolted to this package before we open up the options cattle dog, including:</p>
        <ul class="u-items"><li>Road and Rain Riding Modes</li><li>Full LED lighting and self-cancelling indicators</li><li>Multi-functional instruments with TFT display, ready to accept optional My Triumph connectivity</li><li>Switchable traction control</li><li>ABS (Did I already say that? Yes.)</li><li>Ride-by-wire throttle</li><li>Slip-and-assist clutch</li><li>Immobiliser</li></ul>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">I told you those damn kids are being spoiled! To top all of this off, the Tiger Sport 660 has lengthy 16,000 kay/12-month service intervals, claimed lowest workshop costs in its category and a rideaway price from $14,690. Mercy.</p>
        <p class="paragraph"></p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">Triumph’s Tiger 660 Sport Due to lob in Australian showrooms come early 2022. If you have an eye on the middle rung of the Adventure bike market, it’ll be worth a look for sure.</p>
        `,
      },
    ],
  },
  {
    title: "The Old Gun Returns",
    type: "article",
    image:
      "https://images.prismic.io/bikebizecom/d45d7dd1-ab84-420a-b4b6-bdf6f51b18a4_8oHNspek.jpeg?auto=compress,format",
    slug: "the-old-gun-returns",
    category: "motorcycles",
    author: "Kym Liebig",
    date: "2021/11/10",
    content: [
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong>ADVENTURE TIME – TRIUMPH’S TIGER SPORT 660 AT A GLANCE</strong></p>
        <p class="paragraph">Not to sound too much like Grandpa Simpson, but bloody hell the LAMS crowd have got it good these days. Brilliant bikes as far as the eye can see. Lots of choice, tons of style and feature-rich rides at perfectly reasonable prices.</p>
        <p class="paragraph">The latest entry that’s set to turn the LAMS kids (Okay, I know, they’re not all kids…) into a bunch of spoiled brats is the Triumph Tiger Sport 660. When Triumph first took off in the ADV direction with their Tiger range it seemed an unlikely excursion, but their adventurous bikes quickly gained a reputation for quality, performance and features. At first blush their built-for-beginners 660 looks like more of the same, so let’s crack into it.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Who’s it for?</em></strong></p>
        <p class="paragraph">Let’s set aside the Paris Dakar stuff for a sec. If you’re a new rider, but small bikes make you look like a monkey molesting a football, the Tiger Sport 660 is worth a look right off the bat. Tall enough without being towering (seat height is 835mm), there’s plenty of room for larger folk to get comfortable on the Tiger. Seating is nice and upright too, so seeing over hatchbacks is less of a problem than it might be on a sports bike.</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/b1b61e14-8cce-4f92-8c8e-7acf6da0a5df_DSC_8423.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/e416c3a7-d005-41b4-a91b-1939596efccc_4Qm1qQ8k.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/c06f75a9-1855-465a-88ce-491d553ab237_DSC_8430.JPG?auto=compress,format",
          "https://images.prismic.io/bikebizecom/7fbe76c1-1e75-40db-8b9e-2c64ee9674c3_DSC_8950.JPG?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph">Hang on, hatchbacks? Isn’t this an adventure bike? Well, yes and no. Chassis, suspension and riding position say yes. Alloy wheels and road tyres say less so. The potholed goat tracks we refer to as ‘roads’ in Australia mean that the long-legged, long travel, upright form factor makes more and more sense when tackling commuting, a bit of sporty riding or just a weekend escape. If you’re seriously out to ride unsealed roads and single-track stuff, you might be better off looking for something with spoked wheels and knobby tyres.</p>
        `,
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>What makes it different? Some engine torque…</em></strong></p>
          <p class="paragraph">It’s a Triumph, so if you can hold up three fingers, there’s your cylinder count. Triumph’s triples have a lovely, torquey character, a smooth delivery but a satisfying howl up at the top of the rev range. The predictable power curve is spot-on for new riders, the engine cranking out a LAMS-friendly 35kW at just under 9,000rpm and 59Nm of torque way down at 5,250rpm. That’s handy, and the distinctive three-cylinder exhaust note also makes it mighty entertaining. If you prefer your motorcycle engines not to sound like kitchen appliances, you’re going to like this one.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `
          <p class="paragraph"><strong><em>Handling and brakes</em></strong></p>
          <p class="paragraph">Triumph have set out to create the benchmark for handling in the ‘middleweight adventure sports’ category and on paper they seem to have a winner. Up front there are 41mm Showa upside down cartridge forks and at the rear, a Showa monoshock with remote hydraulic preload adjustment – a handy feature on a bike designed with luggage and pillions in mind. Stoppers at the front are twin 310mm discs chomped by Nissin calipers, and of course standard ABS. Tyres are Michelin Road 5’s for a nice mix of comfort, grip and durability.</p>
          <p class="paragraph">Of course a good measure of handling springs from chassis design, and here Triumph are drawing on their Tiger track record of sweet, neutral handling bikes with a trustworthy feel, yet a good serve of agility.</p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph"><strong><em>Other stuff that matters</em></strong></p>
        <p class="paragraph">Style? Triumph score big points here, bringing on a smooth blend of ADV-meets-sporting sleekness. For those who prefer streamlining and smooth lines in a bike rather than the ‘4WD’ look, there’s a lot to like. Every design feature is carefully thought out, nothing looks like an afterthought.&nbsp;</p>
        <p class="paragraph">Befitting its adventurous nature, the Tiger 660 has a generous 17-litre fuel tank, which ought to give decent range. The standard screen is height-adjustable with one hand, and tall enough to take the windblast off reasonably lofty folks. Seating is generous and comfy for two. Mounts are nicely integrated should you choose to grab a pair of accessory panniers.</p>
        <p class="paragraph">Should you wish to see the dessert menu, Triumph is standing by with no less than 40 dedicated accessories.&nbsp;</p>
        `,
      },
      {
        type: "sliderWrap",
        value: [
          "https://images.prismic.io/bikebizecom/bb7fc13a-285f-4ae5-8381-19d684586272_yWT55xRP.jpeg?auto=compress,format",
          "https://images.prismic.io/bikebizecom/2ca55be7-7631-472d-86f3-cbf15ef0f4a1_10552437_519521844860458_6859897789346875179_n.jpg?auto=compress,format",
        ],
      },
      {
        type: "textSection",
        value: `

        <p class="paragraph"><strong><em>Would you like fries with that?&nbsp;</em></strong></p>
        <p class="paragraph">Okay, maybe not fries, but Triumph really have gone all-in to supersize the standard inclusions on the entry-level Tiger. There’s a serious swag of kit bolted to this package before we open up the options cattle dog, including:</p>
        <ul class="u-items"><li>Road and Rain Riding Modes</li><li>Full LED lighting and self-cancelling indicators</li><li>Multi-functional instruments with TFT display, ready to accept optional My Triumph connectivity</li><li>Switchable traction control</li><li>ABS (Did I already say that? Yes.)</li><li>Ride-by-wire throttle</li><li>Slip-and-assist clutch</li><li>Immobiliser</li></ul>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">I told you those damn kids are being spoiled! To top all of this off, the Tiger Sport 660 has lengthy 16,000 kay/12-month service intervals, claimed lowest workshop costs in its category and a rideaway price from $14,690. Mercy.</p>
        <p class="paragraph"></p>
        `,
      },
      {
        type: "textSection",
        value: `
        <p class="paragraph">Triumph’s Tiger 660 Sport Due to lob in Australian showrooms come early 2022. If you have an eye on the middle rung of the Adventure bike market, it’ll be worth a look for sure.</p>
        `,
      },
    ],
  },
];

export const profileData = {
  firstName: "Myles",
  lastName: "Condon",
  email: "myles@gmail.com",
  mobile: "0428876876",
  address: "123 Harper Place, Chippendale, NSW, 2008 Australia",
};

export const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
export const mockBrands = [
  {
    name: "Accossato",
    slug: "accossato",
  },
  {
    name: "Ace Bikes",
    slug: "ace-bikes",
  },
  {
    name: "Aerofast",
    slug: "aerofast",
  },
  {
    name: "AGV",
    slug: "agv",
  },
  {
    name: "Airoh",
    slug: "airoh",
  },
  {
    name: "Akrapovic",
    slug: "akrapovic",
  },
  {
    name: "Alpine Hearing Protection",
    slug: "alpine-hearing-protection",
  },
  {
    name: "Alpinestars",
    slug: "alpinestars",
  },
  {
    name: "Arai",
    slug: "arai",
  },
  {
    name: "Argon",
    slug: "argon",
  },
  {
    name: "Ariete",
    slug: "ariete",
  },
  {
    name: "Arrow",
    slug: "arrow",
  },
  {
    name: "Atsko",
    slug: "atsko",
  },
  {
    name: "Barkbusters",
    slug: "barkbusters",
  },
  {
    name: "Bearing Worx",
    slug: "bearing-worx",
  },
  {
    name: "Bell",
    slug: "bell",
  },
  {
    name: "Belray",
    slug: "belray",
  },
  {
    name: "Bering",
    slug: "bering",
  },
  {
    name: "Biltwell",
    slug: "biltwell",
  },
  {
    name: "BMC",
    slug: "bmc",
  },
  {
    name: "BMW Accessories",
    slug: "bmw-accessories",
  },
  {
    name: "Bobster Eyewear",
    slug: "bobster-eyewear",
  },
  {
    name: "Bridgestone",
    slug: "bridgestone",
  },
  {
    name: "Bull Bar",
    slug: "bull-bar",
  },
  {
    name: "Bull-It",
    slug: "bull-it",
  },
  {
    name: "C-Tek",
    slug: "c-tek",
  },
  {
    name: "Capit",
    slug: "capit",
  },
  {
    name: "Cardo",
    slug: "cardo",
  },
  {
    name: "Cargol",
    slug: "cargol",
  },
  {
    name: "Castrol",
    slug: "castrol",
  },
  {
    name: "Champion",
    slug: "champion",
  },
  {
    name: "Coocase",
    slug: "coocase",
  },
  {
    name: "CPR",
    slug: "cpr",
  },
];

export const mockProducts = [
  {
    id: 1,
    image: "/production-1.png",
    title: "Alpinestars Faster 3 Ride Boots",
    price: 345.49,
    discount_price: 299.99,
    rating: 4,
    review_count: 22,
    isSpecial: false,
    suggest: "",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 2,
    title: "Alpinestars Faster 3 Ride Boots",
    image: "/production-1.png",
    price: 345.49,
    discount_price: 299.99,
    rating: 4,
    review_count: 22,
    isSpecial: false,
    suggest: "",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 3,
    title: "Alpinestars Faster 3 Ride Boots",
    image: "/production-2.png",
    price: 218.49,
    rating: 5,
    review_count: 2,
    isSpecial: true,
    suggest: "",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 4,
    title: "Alpinestars Faster 3 Ride Boots",
    image: "/production-2.png",
    price: 218.49,
    salePrice: 199.99,
    rating: 4,
    review_count: 22,
    isSpecial: false,
    suggest: "This fits your 2022 Aprilia RS660",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 5,
    image: "/production-1.png",
    title: "Alpinestars Faster 3 Ride Boots",
    price: 345.49,
    discount_price: 299.99,
    rating: 4,
    review_count: 22,
    isSpecial: false,
    suggest: "",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 6,
    title: "Alpinestars Faster 3 Ride Boots",
    image: "/production-1.png",
    price: 345.49,
    discount_price: 299.99,
    rating: 4,
    review_count: 22,
    isSpecial: false,
    suggest: "",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 7,
    title: "Alpinestars Faster 3 Ride Boots",
    image: "/production-2.png",
    price: 218.49,
    rating: 5,
    review_count: 2,
    isSpecial: true,
    suggest: "",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
  {
    id: 8,
    title: "Alpinestars Faster 3 Ride Boots",
    image: "/production-2.png",
    price: 218.49,
    salePrice: 199.99,
    rating: 4,
    review_count: 22,
    isSpecial: false,
    suggest: "This fits your 2022 Aprilia RS660",
    url_key: "alpinestars-faster-3-ride-boots",
    colours: ['{"hex": "#FFFFFF"}', '{"hex": "#7FAA78"}', '{"hex": "#789EAA"}', '{"hex": "#787DAA"}'],
  },
];

export const mockBikes = [
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 1",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 2",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 3",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 4",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 5",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 6",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 7",
  },
  {
    brand: "Yamaha",
    year: "2023",
    model: "Yamaha 2023 model 8",
  },

  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 1",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 2",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 3",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 4",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 5",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 6",
  },
  {
    brand: "Yamaha",
    year: "2022",
    model: "Yamaha 2022 model 7",
  },

  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha 2021 model 1",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha 2021 model 2",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha 2021 model 3",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha 2021 model 4",
  },
  {
    brand: "Yamaha",
    year: "2021",
    model: "Yamaha 2021 model 5",
  },
  {
    brand: "Yamaha",
    year: "2020",
    model: "Yamaha 2020 model 1",
  },
  {
    brand: "Yamaha",
    year: "2019",
    model: "Yamaha 2019 model 1",
  },
  {
    brand: "Yamaha",
    year: "2018",
    model: "Yamaha 2018 model 1",
  },
  {
    brand: "Yamaha",
    year: "2017",
    model: "Yamaha 2017 model 1",
  },
  {
    brand: "Yamaha",
    year: "2016",
    model: "Yamaha 2016 model 1",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 1",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 2",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 3",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 4",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 5",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 6",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 7",
  },
  {
    brand: "Honda",
    year: "2023",
    model: "Honda 2023 model 8",
  },

  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 1",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 2",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 3",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 4",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 5",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 6",
  },
  {
    brand: "Honda",
    year: "2022",
    model: "Honda 2022 model 7",
  },

  {
    brand: "Honda",
    year: "2021",
    model: "Honda 2021 model 1",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda 2021 model 2",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda 2021 model 3",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda 2021 model 4",
  },
  {
    brand: "Honda",
    year: "2021",
    model: "Honda 2021 model 5",
  },
  {
    brand: "Honda",
    year: "2020",
    model: "Honda 2020 model 1",
  },
  {
    brand: "Honda",
    year: "2019",
    model: "Honda 2019 model 1",
  },
  {
    brand: "Honda",
    year: "2018",
    model: "Honda 2018 model 1",
  },
  {
    brand: "Honda",
    year: "2017",
    model: "Honda 2017 model 1",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 1",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 2",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 3",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 4",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 5",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 6",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 7",
  },
  {
    brand: "Suzuki",
    year: "2023",
    model: "Suzuki 2023 model 8",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 1",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 2",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 3",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 4",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 5",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 6",
  },
  {
    brand: "Suzuki",
    year: "2022",
    model: "Suzuki 2022 model 7",
  },

  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki 2021 model 1",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki 2021 model 2",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki 2021 model 3",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki 2021 model 4",
  },
  {
    brand: "Suzuki",
    year: "2021",
    model: "Suzuki 2021 model 5",
  },
  {
    brand: "Suzuki",
    year: "2020",
    model: "Suzuki 2020 model 1",
  },
  {
    brand: "Suzuki",
    year: "2019",
    model: "Suzuki 2019 model 1",
  },
  {
    brand: "Suzuki",
    year: "2018",
    model: "Suzuki 2018 model 1",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 1",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 2",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 3",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 4",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 5",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 6",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 7",
  },
  {
    brand: "Triumph",
    year: "2023",
    model: "Triumph 2023 model 8",
  },

  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 1",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 2",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 3",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 4",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 5",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 6",
  },
  {
    brand: "Triumph",
    year: "2022",
    model: "Triumph 2022 model 7",
  },

  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph 2021 model 1",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph 2021 model 2",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph 2021 model 3",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph 2021 model 4",
  },
  {
    brand: "Triumph",
    year: "2021",
    model: "Triumph 2021 model 5",
  },
  {
    brand: "Triumph",
    year: "2020",
    model: "Triumph 2020 model 1",
  },
  {
    brand: "Triumph",
    year: "2019",
    model: "Triumph 2019 model 1",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 1",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 2",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 3",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 4",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 5",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 6",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 7",
  },
  {
    brand: "BMW",
    year: "2023",
    model: "BMW 2023 model 8",
  },

  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 1",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 2",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 3",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 4",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 5",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 6",
  },
  {
    brand: "BMW",
    year: "2022",
    model: "BMW 2022 model 7",
  },

  {
    brand: "BMW",
    year: "2021",
    model: "BMW 2021 model 1",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW 2021 model 2",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW 2021 model 3",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW 2021 model 4",
  },
  {
    brand: "BMW",
    year: "2021",
    model: "BMW 2021 model 5",
  },
  {
    brand: "BMW",
    year: "2020",
    model: "BMW 2020 model 1",
  },
  {
    brand: "BMW",
    year: "2019",
    model: "BMW 2019 model 1",
  },
  {
    brand: "BMW",
    year: "2018",
    model: "BMW 2018 model 1",
  },
  {
    brand: "BMW",
    year: "2017",
    model: "BMW 2017 model 1",
  },
];

export const mockPartsAndAccessoryCollections = [
  {
    name: "Bearings & Seals",
    url: "/vehicles/yamaha-2016-model-1/bearings-and-seals",
    icon: IconBearings.src,
    categories: [
      {
        name: "Fork Seals",
        url: "/vehicles/yamaha-2016-model-1/bearings-and-seals/fork-seals",
      },
      {
        name: "Linkage Kits",
        url: "/vehicles/yamaha-2016-model-1/bearings-and-seals/linkage-kits",
      },
      {
        name: "Shocks",
        url: "/vehicles/yamaha-2016-model-1/bearings-and-seals/shocks",
      },
      {
        name: "Steering",
        url: "/vehicles/yamaha-2016-model-1/bearings-and-seals/steering",
      },
      {
        name: "Swingarm",
        url: "/vehicles/yamaha-2016-model-1/bearings-and-seals/swingarm",
      },
    ],
  },
  {
    name: "Body",
    url: "/vehicles/yamaha-2016-model-1/body",
    icon: IconBody.src,
    categories: [
      {
        name: "Blanking Plates & Frame Plugs",
        url: "/vehicles/yamaha-2016-model-1/body/blanking-plates-frame-plugs",
      },
      {
        name: "Bolt Kits",
        url: "/vehicles/yamaha-2016-model-1/body/bolt-kits",
      },
      {
        name: "Breather Vents",
        url: "/vehicles/yamaha-2016-model-1/body/breather-vents",
      },
      {
        name: "Fairings",
        url: "/vehicles/yamaha-2016-model-1/body/fairings",
      },
      {
        name: "Fender",
        url: "/vehicles/yamaha-2016-model-1/body/fender",
      },
      {
        name: "Fluid Reservoirs & Caps",
        url: "/vehicles/yamaha-2016-model-1/body/fluid-reservoirs-caps",
      },
      {
        name: "Fuel Caps",
        url: "/vehicles/yamaha-2016-model-1/body/fuel-caps",
      },
      {
        name: "Screens & Shields",
        url: "/vehicles/yamaha-2016-model-1/body/screens-shields",
      },
      {
        name: "Seats & Covers",
        url: "/vehicles/yamaha-2016-model-1/body/seats-and-covers",
      },
      {
        name: "Swingarm Spools",
        url: "/vehicles/yamaha-2016-model-1/body/swingarm-spools",
      },
      {
        name: "Tail Tidy",
        url: "/vehicles/yamaha-2016-model-1/body/tail-tidy",
      },
    ],
  },
  {
    name: "Brakes",
    url: "/vehicles/yamaha-2016-model-1/brakes",
    icon: IconBrakes.src,
    categories: [
      {
        name: "Brake Components",
        url: "/vehicles/yamaha-2016-model-1/brakes/brake-components",
      },
      {
        name: "Brake Discs",
        url: "/vehicles/yamaha-2016-model-1/brakes/brake-discs",
      },
      {
        name: "Brake Pads",
        url: "/vehicles/yamaha-2016-model-1/brakes/brake-pads",
      },
    ],
  },
  {
    name: "Chain",
    url: "/vehicles/yamaha-2016-model-1/chain",
    icon: IconChain.src,
    categories: [],
  },
  {
    name: "Clutch",
    url: "/vehicles/yamaha-2016-model-1/clutch",
    icon: IconClutch.src,
    categories: [
      {
        name: "Clutch Components",
        url: "/vehicles/yamaha-2016-model-1/clutch/clutch-components",
      },
      {
        name: "Clutch Kits",
        url: "/vehicles/yamaha-2016-model-1/clutch/clutch-kits",
      },
    ],
  },
  {
    name: "Electrical",
    url: "/vehicles/yamaha-2016-model-1/electrical",
    icon: IconElectrical.src,
    categories: [
      {
        name: "Batteries",
        url: "/vehicles/yamaha-2016-model-1/electrical/batteries",
      },
      {
        name: "Battery Chargers",
        url: "/vehicles/yamaha-2016-model-1/electrical/battery-chargers",
      },
      {
        name: "Electrical Accessories",
        url: "/vehicles/yamaha-2016-model-1/electrical/electrical-accessories",
      },
      {
        name: "Fuel & Ignition Tuning",
        url: "/vehicles/yamaha-2016-model-1/electrical/fuel-and-ignition-tuning",
      },
      {
        name: "Hour Meters",
        url: "/vehicles/yamaha-2016-model-1/electrical/hour-meters",
      },
      {
        name: "Starters",
        url: "/vehicles/yamaha-2016-model-1/electrical/starters",
      },
      {
        name: "Switches",
        url: "/vehicles/yamaha-2016-model-1/electrical/switches",
      },
    ],
  },
  {
    name: "Engine",
    url: "/vehicles/yamaha-2016-model-1/engine",
    icon: IconEngine.src,
    categories: [
      {
        name: "Bearings & Seals",
        url: "/vehicles/yamaha-2016-model-1/engine/bearings-and-seals",
      },
      {
        name: "Big Bore Kits",
        url: "/vehicles/yamaha-2016-model-1/engine/big-bore-kits",
      },
      {
        name: "Camshafts",
        url: "/vehicles/yamaha-2016-model-1/engine/camshafts",
      },
      {
        name: "Carburettor Parts",
        url: "/vehicles/yamaha-2016-model-1/engine/carburettor-parts",
      },
      {
        name: "Conrods",
        url: "/vehicles/yamaha-2016-model-1/engine/conrods",
      },
      {
        name: "Covers",
        url: "/vehicles/yamaha-2016-model-1/engine/covers",
      },
      {
        name: "Crank Kits",
        url: "/vehicles/yamaha-2016-model-1/engine/crank-kits",
      },
      {
        name: "Cylinders",
        url: "/vehicles/yamaha-2016-model-1/engine/cylinders",
      },
      {
        name: "Engine Accessories",
        url: "/vehicles/yamaha-2016-model-1/engine/engine-accessories",
      },
      {
        name: "Gasket Kits",
        url: "/vehicles/yamaha-2016-model-1/engine/gasket-kits",
      },
      {
        name: "Hoses",
        url: "/vehicles/yamaha-2016-model-1/engine/hoses",
      },
      {
        name: "Oil Filler Cap",
        url: "/vehicles/yamaha-2016-model-1/engine/oil-filler-caps",
      },
      {
        name: "Performance Heads",
        url: "/vehicles/yamaha-2016-model-1/engine/performance-heads",
      },
      {
        name: "Piston Kits",
        url: "/vehicles/yamaha-2016-model-1/engine/piston-kits",
      },
      {
        name: "Spark Plugs",
        url: "/vehicles/yamaha-2016-model-1/engine/spark-plugs",
      },
    ],
  },
  {
    name: "Exhausts & Performance",
    url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance",
    icon: IconExhaust.src,
    categories: [
      {
        name: "2 Stroke Pipes",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/2-stroke-pipes",
      },
      {
        name: "2 Stroke Silencers",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/2-stroke-silencers",
      },
      {
        name: "3/4 Exhausts",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/34-exhausts",
      },
      {
        name: "Baffles & Inserts",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/baffles-inserts",
      },
      {
        name: "Clamps & Hangers",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/clamps-hangers",
      },
      {
        name: "Exhaust Accessories",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/exhaust-accessories",
      },
      {
        name: "Exhaust Plugs",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/exhaust-plugs",
      },
      {
        name: "Exhaust Springs",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/exhaust-springs",
      },
      {
        name: "Full Exhaust Systems",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/full-exhaust-systems",
      },
      {
        name: "Headers",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/headers",
      },
      {
        name: "Link Mid Pipes",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/link-mid-pipes",
      },
      {
        name: "Power Commanders",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/power-commanders",
      },
      {
        name: "Slip On Exhausts",
        url: "/vehicles/yamaha-2016-model-1/exhausts-and-performance/slip-on-exhausts",
      },
    ],
  },
  {
    name: "Fluids & Consumables",
    url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables",
    icon: IconFluids.src,
    categories: [
      {
        name: "2 Stroke Oil",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/2-stroke-oil",
      },
      {
        name: "4 Stroke Oil",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/4-stroke-oil",
      },
      {
        name: "Air Filter Oils",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/air-filter-oils",
      },
      {
        name: "Brake Fluid",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/brake-fluid",
      },
      {
        name: "Chain Lube & Clean",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/chain-lube-clean",
      },
      {
        name: "Coolants",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/coolants",
      },
      {
        name: "Fork Oil",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/fork-oil",
      },
      {
        name: "Gear Box Oil",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/gear-box-oil",
      },
      {
        name: "Maintenance & Service",
        url: "/vehicles/yamaha-2016-model-1/fluids-and-consumables/maintenance-service",
      },
    ],
  },
  {
    name: "Foot Controls",
    url: "/vehicles/yamaha-2016-model-1/foot-controls",
    icon: IconFootControls.src,
    categories: [
      {
        name: "Brake Pedals",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/brake-pedals",
      },
      {
        name: "Foot Peg Mounts",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/foot-peg-mounts",
      },
      {
        name: "Foot Pegs",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/foot-pegs",
      },
      {
        name: "Gear Levers",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/gear-levers",
      },
      {
        name: "Kick Start Lever",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/kick-start-lever",
      },
      {
        name: "Quick Shifters",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/quick-shifters",
      },
      {
        name: "Rear Sets",
        url: "/vehicles/yamaha-2016-model-1/foot-controls/rear-sets",
      },
    ],
  },
  {
    name: "Hand Controls",
    url: "/vehicles/yamaha-2016-model-1/hand-controls",
    icon: IconHandControls.src,
    categories: [
      {
        name: "Bar Clamps",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/bar-clamps",
      },
      {
        name: "Bar End Adaptors",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/bar-end-adaptors",
      },
      {
        name: "Bar Ends",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/bar-ends",
      },
      {
        name: "Bar Pads",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/bar-pads",
      },
      {
        name: "Clutch Cables",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/clutch-cables",
      },
      {
        name: "Grip Accessories",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/grip-accessories",
      },
      {
        name: "Grips",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/grips",
      },
      {
        name: "Handguards",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/handguards",
      },
      {
        name: "Handlebars, Mounts & Risers",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/handlebars-mounts-risers",
      },
      {
        name: "Heated Grips",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/heated-grips",
      },
      {
        name: "Levers",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/levers",
      },
      {
        name: "Throttle Cables",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/throttle-cables",
      },
      {
        name: "Throttle Kits",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/throttle-kits",
      },
      {
        name: "Triple Clamps",
        url: "/vehicles/yamaha-2016-model-1/hand-controls/triple-clamps",
      },
    ],
  },
  {
    name: "Lighting & Electrical",
    url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical",
    icon: IconLighting.src,
    categories: [
      {
        name: "Bulbs",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/bulbs",
      },
      {
        name: "Headlights & Guards",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/headlights-and-guards",
      },
      {
        name: "Indicator Adaptors",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/indicator-adaptors",
      },
      {
        name: "Indicator Wiring Kits",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/indicator-wiring-kits",
      },
      {
        name: "Indicators",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/indicators",
      },
      {
        name: "Light Mounts",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/light-mounts",
      },
      {
        name: "Lighting Accessories",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/lighting-accessories",
      },
      {
        name: "Running & Drive Lights",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/running-and-drive-lights",
      },
      {
        name: "Tail & Brake Lights",
        url: "/vehicles/yamaha-2016-model-1/lighting-and-electrical/tail-and-brake-lights",
      },
    ],
  },
  {
    name: "Luggage",
    url: "/vehicles/yamaha-2016-model-1/luggage",
    icon: IconLuggage.src,
    categories: [
      {
        name: "Fitting Kits",
        url: "/vehicles/yamaha-2016-model-1/luggage/fitting-kits",
      },
      {
        name: "Panniers",
        url: "/vehicles/yamaha-2016-model-1/luggage/panniers",
      },
      {
        name: "Phone & GPS Mounts",
        url: "/vehicles/yamaha-2016-model-1/luggage/phone-gps-mounts",
      },
      {
        name: "Racks",
        url: "/vehicles/yamaha-2016-model-1/luggage/racks",
      },
      {
        name: "Saddlebags",
        url: "/vehicles/yamaha-2016-model-1/luggage/saddlebags",
      },
      {
        name: "Tail Bags",
        url: "/vehicles/yamaha-2016-model-1/luggage/tail-bags",
      },
      {
        name: "Tank Bags",
        url: "/vehicles/yamaha-2016-model-1/luggage/tank-bags",
      },
      {
        name: "Top Boxes",
        url: "/vehicles/yamaha-2016-model-1/luggage/top-boxes",
      },
      {
        name: "Backpacks",
        url: "/vehicles/yamaha-2016-model-1/luggage/backpacks",
      },
      {
        name: "Gear Haulers",
        url: "/vehicles/yamaha-2016-model-1/luggage/gear-haulers",
      },
    ],
  },
  {
    name: "Mirrors",
    url: "/vehicles/yamaha-2016-model-1/mirrors",
    icon: IconMirrors.src,
    categories: [
      {
        name: "Mirror Adaptors",
        url: "/vehicles/yamaha-2016-model-1/mirrors/mirror-adaptors",
      },
      {
        name: "Mirror Sets",
        url: "/vehicles/yamaha-2016-model-1/mirrors/mirror-sets",
      },
      {
        name: "Single Mirrors",
        url: "/vehicles/yamaha-2016-model-1/mirrors/single-mirrors",
      },
    ],
  },
  {
    name: "Plastics",
    url: "/vehicles/yamaha-2016-model-1/plastics",
    icon: IconPlastics.src,
    categories: [
      {
        name: "Disc Guards",
        url: "/vehicles/yamaha-2016-model-1/plastics/disc-guards",
      },
      {
        name: "Fender",
        url: "/vehicles/yamaha-2016-model-1/plastics/fender",
      },
      {
        name: "Fork Guards",
        url: "/vehicles/yamaha-2016-model-1/plastics/fork-guards",
      },
      {
        name: "Front Plates",
        url: "/vehicles/yamaha-2016-model-1/plastics/front-plates",
      },
      {
        name: "Fuel Tanks",
        url: "/vehicles/yamaha-2016-model-1/plastics/fuel-tanks",
      },
      {
        name: "Ignition Cover",
        url: "/vehicles/yamaha-2016-model-1/plastics/ignition-cover",
      },
      {
        name: "Mud Flaps",
        url: "/vehicles/yamaha-2016-model-1/plastics/mud-flaps",
      },
      {
        name: "Plastic Kits",
        url: "/vehicles/yamaha-2016-model-1/plastics/plastics-kits",
      },
      {
        name: "Radiator Shrouds",
        url: "/vehicles/yamaha-2016-model-1/plastics/radiator-shrouds",
      },
      {
        name: "Side Covers",
        url: "/vehicles/yamaha-2016-model-1/plastics/side-covers",
      },
      {
        name: "Swingarm Sliders",
        url: "/vehicles/yamaha-2016-model-1/plastics/swingarm-sliders",
      },
    ],
  },
  {
    name: "Protection",
    url: "/vehicles/yamaha-2016-model-1/protection",
    icon: IconPartsProtection.src,
    categories: [
      {
        name: "Axle Sliders",
        url: "/vehicles/yamaha-2016-model-1/protection/axle-sliders",
      },
      {
        name: "Bash Plates",
        url: "/vehicles/yamaha-2016-model-1/protection/bash-plates",
      },
      {
        name: "Chain Guards",
        url: "/vehicles/yamaha-2016-model-1/protection/chain-guards",
      },
      {
        name: "Crash Bars",
        url: "/vehicles/yamaha-2016-model-1/protection/crash-bars",
      },
      {
        name: "Engine Covers",
        url: "/vehicles/yamaha-2016-model-1/protection/engine-covers",
      },
      {
        name: "Exhaust Guards",
        url: "/vehicles/yamaha-2016-model-1/protection/exhaust-guards",
      },
      {
        name: "Fork Protectors",
        url: "/vehicles/yamaha-2016-model-1/protection/fork-protectors",
      },
      {
        name: "Frame Sliders",
        url: "/vehicles/yamaha-2016-model-1/protection/frame-sliders",
      },
      {
        name: "Radiator Guards",
        url: "/vehicles/yamaha-2016-model-1/protection/radiator-guards",
      },
      {
        name: "Sprocket Covers",
        url: "/vehicles/yamaha-2016-model-1/protection/sprocket-covers",
      },
      {
        name: "Tail Sliders",
        url: "/vehicles/yamaha-2016-model-1/protection/tail-sliders",
      },
      {
        name: "Tank Protectors and Pads",
        url: "/vehicles/yamaha-2016-model-1/protection/tank-protectors-pads",
      },
      {
        name: "Dash Protectors",
        url: "/vehicles/yamaha-2016-model-1/protection/dash-protectors",
      },
    ],
  },
  {
    name: "Security",
    url: "/vehicles/yamaha-2016-model-1/security",
    icon: IconSecurity.src,
    categories: [
      {
        name: "Alarms",
        url: "/vehicles/yamaha-2016-model-1/security/alarms",
      },
      {
        name: "Chains and Cables",
        url: "/vehicles/yamaha-2016-model-1/security/chains-cables",
      },
      {
        name: "Disc Locks",
        url: "/vehicles/yamaha-2016-model-1/security/disc-locks",
      },
      {
        name: "Helmet Locks",
        url: "/vehicles/yamaha-2016-model-1/security/helmet-locks",
      },
      {
        name: "Locks",
        url: "/vehicles/yamaha-2016-model-1/security/locks",
      },
    ],
  },
  {
    name: "Service & Maintenance",
    url: "/vehicles/yamaha-2016-model-1/service-and-maintenance",
    icon: IconService.src,
    categories: [
      {
        name: "Air Box Covers",
        url: "/vehicles/yamaha-2016-model-1/service-and-maintenance/air-box-covers",
      },
      {
        name: "Air Filters and Kits",
        url: "/vehicles/yamaha-2016-model-1/service-and-maintenance/air-filters-kits",
      },
      {
        name: "Oil Filters and Kits",
        url: "/vehicles/yamaha-2016-model-1/service-and-maintenance/oil-filter-kits",
      },
      {
        name: "Pick Ups",
        url: "/vehicles/yamaha-2016-model-1/service-and-maintenance/pick-ups",
      },
      {
        name: "Stands",
        url: "/vehicles/yamaha-2016-model-1/service-and-maintenance/stands",
      },
      {
        name: "Tools",
        url: "/vehicles/yamaha-2016-model-1/service-and-maintenance/tools",
      },
    ],
  },
  {
    name: "Storage & Transport",
    url: "/vehicles/yamaha-2016-model-1/storage-and-transport",
    icon: IconStorage.src,
    categories: [
      {
        name: "Bike Covers",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/bike-covers",
      },
      {
        name: "Fork Stands",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/fork-stands",
      },
      {
        name: "Ramps",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/ramps",
      },
      {
        name: "Stands",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/stands",
      },
      {
        name: "Storage",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/storage",
      },
      {
        name: "Tie Downs",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/tie-downs",
      },
      {
        name: "Wheel Chocks",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/wheel-chocks",
      },
      {
        name: "Workshop Mats",
        url: "/vehicles/yamaha-2016-model-1/storage-and-transport/workshop-mats",
      },
    ],
  },
  {
    name: "Tyres & Wheels",
    url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels",
    icon: IconTyres.src,
    categories: [
      {
        name: "Tyre Pair Deals",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/tyre-pair-deals",
      },
      {
        name: "Adventure Tyres",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/adventure-tyres",
      },
      {
        name: "Axles and Spacers",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/axles-and-spacers",
      },
      {
        name: "Front Rims",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/front-rims",
      },
      {
        name: "Off-Road Tyres",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/offroad-tyres",
      },
      {
        name: "Quad Bike Tyres",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/quad-bike-tyres",
      },
      {
        name: "Rear Rims",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/rear-rims",
      },
      {
        name: "Rim Locks",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/rim-locks",
      },
      {
        name: "Road Tyres",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/road-tyres",
      },
      {
        name: "Scooter Tyres",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/scooter-tyres",
      },
      {
        name: "Training Wheels",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/training-wheels",
      },
      {
        name: "Tubes",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/tubes",
      },
      {
        name: "Tyre Warmers",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/tyre-warmers",
      },
      {
        name: "Wheel Bearing Kits",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/wheel-bearing-kits",
      },
      {
        name: "Wheel Hardware",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/wheel-hardware",
      },
      {
        name: "Wheel Kits",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/wheel-kits",
      },
      {
        name: "Wheel Tape",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/wheel-tape",
      },
      {
        name: "Race Tyres",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/race-tyres",
      },
      {
        name: "Gauges and Pumps",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/gauges-and-pumps",
      },
      {
        name: "Valve Stems",
        url: "/vehicles/yamaha-2016-model-1/tyres-and-wheels/valve-stems",
      },
    ],
  },
];
export const storeLocations = [
  {
    name: "BIKEBIZ PARRAMATTA",
    iconAlt: "footer-shop",
    logoStyles: { width: "16px", height: "16px" },
    brands: [
      {
        alt: "white-yamaha",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/yamaha`,
        navIcon: {
          width: "73px",
          height: "32.92px",
        },
      },
      {
        alt: "white-suzuki",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/suzuki`,
        navIcon: {
          width: "44px",
          height: "31px",
        },
      },
      {
        alt: "white-aprilia",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/aprilia`,
        navIcon: {
          width: "62.69px",
          height: "26.04px",
        },
      },
      {
        alt: "white-kymco",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/kymco`,
        navIcon: {
          width: "48px",
          height: "36px",
        },
      },
    ],
    location: {
      title: "Location",
      address: "7 Great Western Hwy, Parramatta, NSW, 2150",
      map: "https://goo.gl/maps/Vfx7djK1bbh7VkVFA",
      googleMapLatLng: {
        lat: -33.82039,
        lng: 151.00307,
        zoom: 16,
      },
      googleMapImage: "/map-parramatta.png",
    },
    categoryItems: [
      {
        title: "New & Used Motorcycle Enquiries",
        tradingTime: "Mon - Fri 8:30am - 5:00pm / Sat 8:30am - 4:00pm",
        callPhone: "+612 8830 0555",
        emailAddress: "stanb@bikebiz.com.au",
        insideUrl: `${process.env.NEXT_PUBLIC_URL}/about/dealership-bikebiz-parramatta`,
      },
      {
        title: "Service & Workshop Enquiries",
        tradingTime: "Mon - Fri 8:30am - 5:00pm / Sat Closed",
        callPhone: "+612 8830 0551",
        emailAddress: "service@bikebiz.com.au",
        insideUrl: `${process.env.NEXT_PUBLIC_URL}/about/service-bikebiz-parramatta`,
      },
      {
        title: "Spares & Accessories Enquiries",
        tradingTime: "Mon - Fri 8:30am - 5:00pm / Sat 8:30am - 4:00pm",
        callPhone: "+612 8830 0550",
        emailAddress: "spares@bikebiz.com.au",
        insideUrl: `${process.env.NEXT_PUBLIC_URL}/about/accessories-bikebiz-parramatta`,
      },
    ],
  },
  {
    name: "BIKEBIZ GRANVILLE",
    iconAlt: "footer-shop",
    logoStyles: { width: "16px", height: "16px" },
    brands: [
      {
        alt: "white-kawasaki",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/kawasaki`,
        navIcon: {
          width: "87.51px",
          height: "10.33px",
        },
      },
      {
        alt: "white-triumph",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/triumph`,
        navIcon: {
          width: "78.77px",
          height: "16.78px",
        },
      },
      {
        alt: "white-bmw",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/bmw`,
        navIcon: {
          width: "128px",
          height: "30px",
        },
      },
      {
        alt: "white-honda",
        href: `${process.env.NEXT_PUBLIC_URL}/new-bikes/honda`,
        navIcon: {
          width: "118px",
          height: "36px",
        },
      },
    ],
    location: {
      title: "Location",
      address: "274 Parramatta Rd, Granville, NSW, 2142",
      map: "https://goo.gl/maps/jE9fjZCCFWKj73Gx6",
      googleMapLatLng: {
        lat: -33.828582,
        lng: 151.006129,
        zoom: 16,
      },
      googleMapImage: "/map-granville.png",
    },
    categoryItems: [
      {
        title: "New & Used Motorcycle Enquiries",
        tradingTime: "Mon - Fri 8:30am - 5:00pm / Sat 8:30am - 4:00pm",
        callPhone: "+612 9682 2922",
        emailAddress: "salesgra@bikebiz.com.au",
        insideUrl: `${process.env.NEXT_PUBLIC_URL}/about/dealership-bikebiz-granville`,
      },
      {
        title: "Service & Workshop Enquiries",
        tradingTime: "Mon - Fri 8:30am - 5:00pm / Sat Closed",
        callPhone: "+612 9682 2951",
        emailAddress: "service2@bikebiz.com.au",
        insideUrl: `${process.env.NEXT_PUBLIC_URL}/about/service-bikebiz-granville`,
      },
      {
        title: "Spares & Accessories Enquiries",
        tradingTime: "Mon - Fri 8:30am - 5:00pm / Sat 8:30am - 4:00pm",
        callPhone: "+612 9682 2950",
        emailAddress: "sparesgra@bikebiz.com.au",
        insideUrl: `${process.env.NEXT_PUBLIC_URL}/about/accessories-bikebiz-granville`,
      },
    ],
  },
  {
    name: "BIKEBIZ ONLINE",
    iconAlt: "footer-widget",
    logoStyles: { width: "16px", height: "16px" },
    brands: [],
    location: null,
    categoryItems: [],
    tradingTime: "Mon - Fri 9am - 5pm",
    callPhone: "1300 250 450",
    emailAddress: "webshop@bikebiz.com.au",
    isBikebizOnline: true,
  },
  {
    name: "GENERAL ENQUIRIES",
    iconAlt: "footer-cInfo",
    logoStyles: { width: "20px", height: "20px" },
    brands: [],
    location: null,
    categoryItems: [],
    isGeneralEnquiries: true,
    contacts: [
      { name: "Bikebiz Parramatta", callPhone: "+612 8830 0500" },
      {
        name: "Bikebiz Granville",
        callPhone: " +612 9682 2999",
      },
      {
        name: "Bikebiz Online",
        callPhone: "1300 250 450",
      },
    ],
  },
  {
    name: "All transactions are safe and secure",
    iconAlt: "footer-privacy",
    logoStyles: { width: "20px", height: "24px" },
    className: "safeSecure",
    toggleHide: true,
    brands: [
      {
        alt: "white-visa",
        href: "",
        disabled: true,
      },
      {
        alt: "white-mastercard",
        href: "",
        disabled: true,
      },
      {
        alt: "white-amex",
        href: "",
        disabled: true,
      },
      {
        alt: "white-paypal",
        href: "",
        disabled: true,
      },

      {
        alt: "white-gPay",
        href: "",
        disabled: true,
        isDisabled: false,
      },
      {
        alt: "white-klarna",
        href: "",
        disabled: true,
        isDisabled: process.env.NEXT_PUBLIC_KLARNA_DISABLED,
      },
      {
        alt: "white-zippay",
        href: "",
        disabled: true,
        isDisabled: process.env.NEXT_PUBLIC_ZIP_DISABLED,
        height: 13,
        referLink: `${process.env.NEXT_PUBLIC_URL}/zip`,
      },
      {
        alt: "white-afterpay",
        href: "",
      },
      {
        alt: "footer-applePay",
        href: "",
        width: 47,
        height: 33,
        isAppleMark: true,
      },
    ],
    isTransactions: true,
  },
];
export const OEM_P_A = {
  department_image: {
    dimensions: {
      width: 1500,
      height: 1500,
    },
    alt: null,
    copyright: null,
    url: "/genunie_oem/landing-oem.jpg",
  },
  department_preHeader: [
    {
      type: "paragraph",
      text: "OEM",
      spans: [],
    },
  ],
  department_title: [
    {
      type: "heading2",
      text: "OEM Parts",
      spans: [],
    },
  ],
  department_call_to_action: [
    {
      type: "paragraph",
      text: "Shop Now",
      spans: [],
    },
  ],
  image_desktop: {
    dimensions: {
      width: 1840,
      height: 400,
    },
    alt: null,
    copyright: null,
    url: "/genunie_oem/landing-oem.jpg",
  },
  image_mobile: {
    dimensions: {
      width: 312,
      height: 352,
    },
    alt: null,
    copyright: null,
    url: "/genunie_oem/landing-oem.jpg",
  },
  department_slug: "https://parts.bikebiz.com.au/",
};
